import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Feedback() {
  const [feedback, setFeedback] = useState('');
  const primaryColor = "#213F99";
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle submission of feedback here
    try {
      setLoading(true);
      const authToken = sessionStorage.getItem('token');
      const response = await fetch('https://api.dentalai.ai/send_feedback/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({ "message": feedback }),
      });

      if (response.status === 200) {
        setFeedback('');
        setStatus(true);
      } else {
        setLoading(false);
        alert('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className='container'>
      {!status ? (
        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="feedback" className="form-label">Feedback:</label>
            <textarea
              className="form-control"
              style={{ minHeight: "200px", resize: "vertical" }}
              id="feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              required
            />
          </div>
          <button
            className='btn btn-primary fs-5'
            style={{
              backgroundColor: primaryColor,
              color: "#ffffff",
              width: '100%',
              fontWeight: "bold",
              fontFamily: 'Arial, sans-serif',
              border: "none",
              borderRadius: "8px",
              padding: "10px 0",
              cursor: "pointer",
            }}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      ) : (
        <>
          <div className="alert alert-success mt-4" role="alert">
            Your message has been received successfully!
          </div>
          <Link to="/fileview" style={{ textDecoration: 'none', color: primaryColor, fontWeight: "bold" }}>
            Back to Home
          </Link>
        </>
      )}
    </div>
  );
}

export default Feedback;

import React from 'react';
import Benefits from './Benefits';
import Usage from './Usage';
import Join from './Join';
import img from "../Images/mainpag.jpeg";
import { useNavigate } from 'react-router-dom';

export default function Mainpage() {
  const navigate = useNavigate(); 
  return (
    <div style={{ position: 'relative' }}>
      {/* Corrected the image tag */}
      <img src={img} style={{ width: "100vw" }} alt="Main Image" />
<section className="jumbotron text-center text-dark" style={{ position: 'absolute', top: '10%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1', width: '80%', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '20px', borderRadius: '10px' }}>
  <h1 className="display-4 text-dark">Design your crown using online CAD</h1>
  <h6 className="lead text-dark">Revolutionizing Dentistry with Advanced AI Technology</h6>
  <button className="btn btn-info mt-3 mx-2 fs-3" onClick={()=>{navigate("/login")}} style={{ width: '25%' }}> Login </button>
  <button className="btn btn-light mt-3 mx-2 ml-3 fs-3" style={{ width: '25%' }}> See how it works? </button>
</section>

      <Usage />

      
      <Benefits />

      
      <Join />
    </div>
  );
}

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Avatar, Typography, TextField, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from 'react-router-dom';
import image from '../assets/image.jpeg';

function PwdChange() {
  const { uid, token } = useParams(); // Extract uid and token from URL
  const [email,setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(`https://api.dentalai.ai/password_reset/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "email": email }),
      });
      setLoading(false);
      if (response.status === 200) {
        setStatus(true);
        setMessage('Check your inbox! We have sent you an email to reset your password.');
      } else if(response.status === 400){ 
        setMessage("'User with this email does not exist.'");
      }
    } catch (error) {
      setLoading(false);
      setMessage('An error occurred. Please try again later.');
    }
  };

  const verification = (
    <div style={{ fontFamily: 'Manrope, sans-serif' }}>
      <Box
        sx={{
          margin: 8,
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: '0 0 4px 4px rgba(144, 200, 224, 0.4)',
        }}
      >
        <Grid container direction="row" justifyContent="center" spacing={2} sx={{ margin: 4 }}>
          <Grid item xs={4} container justifyContent="center">
            <img src={image} style={{ height: '50vh', width: '50vh', overflow: 'hidden', objectFit: 'cover' }} alt="Password Reset" />
          </Grid>
          <Grid item xs={5}>
            <Avatar sx={{ m: 1, bgcolor: '#213F99' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
              <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="Email Address"
                    label="Email Address"
                    id="Email Address"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
              <button
                className='btn btn-primary'
                style={{
                  backgroundColor: '#213F99',
                  textDecorationColor: '#ffffff',
                  width: '100%',
                  fontFamily: 'Manrope, sans-serif',
                }}
                onClick={handleVerify}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Reset Password'}
              </button>
              {message &&<Typography color={status ? "success" : "error"}>{message}</Typography>}
              <Grid container sx={{ mt: 1 }} justifyContent="flex-end">
                <Grid item>
                  <Typography>
                    Remember your password?
                    <Link to="/login" style={{ textDecoration: 'none', marginLeft: '4px' }}>
                      Sign in
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  return <div>{verification}</div>;
}

export default PwdChange;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LinearProgress ,CircularProgress} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import image from '../assets/image.jpeg';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Dental AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();




function Signin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      navigate('/fileview');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('https://apiv1.dev.dentalai.ai/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: username, password: password }),
      });

    

      if (response.status === 200) {
        const { token } = await response.json();

        // Store the token in session storage
        sessionStorage.setItem('token', token);

        
        // Use navigate to navigate to "/upload"
        navigate('/fileview');
      } else {
        
        setError('Invalid credentials. Please try again.');
      }
    } catch (error) {
      
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
    <Box container
        sx={{
            margin: 8,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: "0 0 4px 4px rgba(144, 200, 224, 0.4)",
            fontFamily: 'Manrope, sans-serif'
        }}
    >
        <Grid container justifyContent="center" spacing={2} sx={{margin:4}}>
        <Grid item xs={4} container justifyContent="center"  >
    <img src={image} style={{ height: "50vh", width: "50vh",overflow: "hidden",objectFit:"cover" }} />
</Grid>

            <Grid item xs={5}>
                <Avatar sx={{ m: 1, bgcolor: '#213F99' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Username"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Grid container>
                        <button className='btn btn-primary'
                        disabled={username === '' || password === ''}
                            style={{
                                backgroundColor: "#213F99",
                                textDecorationColor: "#ffffff",
                                width: '100%',
                                fontFamily: 'Manrope, sans-serif'
                            }}
                            onClick={handleSubmit}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
                        </button>
                    </Grid>
                    <Grid container sx={{mt:1}}>
    <Grid item xs>
    <Typography>
        <Link to="/forgot-password" style={{ textDecoration: 'none',marginLeft:"2px" }}>
                Forgot Password?
        </Link>
        </Typography>
    </Grid>
    <Grid item>
      <Typography>Don't have an account? Fill up 
        <Link to="/signup" style={{ textDecoration: 'none',marginLeft:"4px" }}>
                this form
        </Link>
        
        </Typography>
    </Grid>
</Grid>



                </Box>
            </Grid>
        </Grid>
        <Copyright sx={{ mt: 8, mb: 4 }} />
    </Box>
   
</ThemeProvider>

  );
}

export default Signin;

import React, { useState, useEffect } from 'react';
import { IoAddCircleSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Dashboard } from '../assets/Dashboard.svg';
import { ReactComponent as Dashboard_inactive } from '../assets/Dashboard_inactive.svg';
import { ReactComponent as Account } from '../assets/account_circle.svg';
import { ReactComponent as Account_filled } from '../assets/User.svg';
import { Box, Card, CardContent, List, Typography, LinearProgress } from '@mui/material';
import Chart  from './Dashboard/Chart';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function Listview() {
  const [filesData, setFilesData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [activeSection, setActiveSection] = useState('files');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("User");
  const [orgData, setOrgData] = useState(null);
  const [crownData, setCrownData] = useState({today: 0, week: 0, month: 0, total: 0});

  useEffect(() => {
    fetchData();
    getUSerData();
    getOrgdetail();
    getCrownData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const authToken = sessionStorage.getItem('token');
      const response = await fetch('https://api.dentalai.ai/get_file/', {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });
      const data = await response.json();
      
      setFilesData(data.results.files);
      if (response.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleCardClick = (fileId) => { }

  const getUSerData = async () => {
    try {
      const authToken = sessionStorage.getItem('token');
      const response = await fetch('https://api.dentalai.ai/get_user/', {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });
      const data = await response.json();
      console.log("userdata",data);
      setUser(data.username);
      
      setUserData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getOrgdetail = async () => {
    try {
      const response = await fetch('https://api.dentalai.ai/org_info/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Token ${sessionStorage.getItem('token')}`,
          },
        });
        if (response.status === 200) {
          setLoading(false);
          const data = await response.json(); // Convert response to JSON
            console.log(data); // Log the data
            setOrgData(data);
            console.log(orgData);
          // Redirect to login page
        } else {
          setLoading(false);
        }
  } catch (error) {}
  }
  const getCrownData = async () => {
    try {
      const response = await fetch('https://api.dentalai.ai/crown_data/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Token ${sessionStorage.getItem('token')}`,
          },
        });
        if (response.status === 200) {
          setLoading(false);
          const data = await response.json(); // Convert response to JSON
            console.log(data); // Log the data
            setCrownData(data);
            console.log(crownData);
          // Redirect to login page
        } else {
          setLoading(false);
        }
  } catch (error) {}

  }

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const fetchFiles = async (files_array) => {
    try {
      setLoading(true);
      const downloadedFiles = [];
      for (const fileUrl of files_array) {
        const response = await fetch(fileUrl, {
          method: 'GET',
          mode: 'no-cors',
        });
        const blob = await response.blob();
        if (blob.size > 0) {
          console.log(`Successfully initiated download from ${fileUrl}`);
          const blobUrl = URL.createObjectURL(blob);
          downloadedFiles.push(blobUrl);
        } else {
          console.error(`Failed to download file ${fileUrl}`);
        }
      }
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      
    }
  }
  const organisationKeys = {
    "Organization Name": "org_name",
    "Organization Type": "org_type",
    "Lab Type": "lab_type",
    "Practice Type": "practice_type",
    "No. of Employees": "no_of_employees",
    "Crown volume monthly":"monthly_crown"
  };
  const filecomponent = (
    <>
      <div className='d-flex justify-content-between align-items-center '>
        <div className='d-flex flex-row align-items-center'>
          <span className='fs-5'>Welcome,</span>
          <span className='fs-3' style={{ fontWeight: "bold", marginLeft: '5px' }}>{user ? user : "User"}!</span>
        </div>
        <button className='btn btn-primary ' style={{ backgroundColor: "#213F99" }} onClick={() => navigate('/select')}><div className='p-1'><IoAddCircleSharp /> New Crown </div></button>
      </div>

      <div className='d-flex  align-items-center py-3' style={{ justifyContent: "space-between" }}>
        <div className='card' style={{ width: "20vw", boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', border: '1px solid #213F99', borderRadius: '10px', backgroundColor: 'rgba(241, 241, 241, 0.35)' }}>
          <div className='card-body'>
            <h5 className='card-title'>Today</h5>
            <p className='card-text fs-2 fw-bold'>{crownData.today}</p>
          </div>
        </div>
        <div className='card' style={{ width: "20vw", boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', border: '1px solid #213F99', borderRadius: '10px', backgroundColor: "rgba(241, 241, 241, 0.35)" }}>
          <div className='card-body'>
            <h5 className='card-title'>This Week</h5>
            <p className='card-text fs-2 fw-bold'>{crownData.week}</p>
          </div>
        </div>
        <div className='card' style={{ width: "20vw", boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', border: '1px solid #213F99', borderRadius: '10px', backgroundColor: "rgba(241, 241, 241, 0.35)" }}>
          <div className='card-body'>
            <h5 className='card-title'>This Month</h5>
            <p className='card-text fs-2 fw-bold'>{crownData.month}</p>
          </div>
        </div>
        <div className='card' style={{ width: "20vw", boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', border: '1px solid #213F99', borderRadius: '10px', backgroundColor: "rgba(241, 241, 241, 0.35)" }}>
          <div className='card-body'>
            <h5 className='card-title'>Total</h5>
            <p className='card-text fs-2 fw-bold'>{crownData.total}</p>
          </div>
        </div>
      </div>
      
      <div className='card ' style={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', border: '1px solid #213F99', borderRadius: '10px', width: "auto", height: "auto", backgroundColor: "rgba(241, 241, 241, 0.35)" }}>
      
        <div className='card-body'>
          
          <h5 className='card-title fw-bold'>Design and Export Report</h5>
          {/* <Chart /> */}
          <div className="d-flex  my-2" style={{width:"30%",alignItems:"flex-end",justifyContent:"flex-end"}}>
    <input type="text" className="form-control mt-2 mb-2" placeholder="Search..." />
</div>
          <Box sx={{ display: 'flex', justifyContent: "space-between", borderBottom: '1px solid #ccc', mb: 2, py: 1 }}>
            <Typography variant="subtitle1" >Name</Typography>
            <Typography variant="subtitle1" >Status</Typography>
            <Typography variant="subtitle1" >Date Modified</Typography>
          </Box>
          <List>
            {filesData ? filesData.map((file, index) => (
              <React.Fragment key={file.id}>
                <Card
                  variant="outlined"
                  sx={{
                    mb: 2,
                    backgroundColor: 'rgba(241, 241, 241, 0.35)',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#fff', // Change background color on hover
                    },
                  }}
                  onClick={() => handleCardClick(file.id)} // Handle card click event
                >
                  <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body1" >{`File ${index + 1}`}</Typography>
                    <Typography variant="body1" >Crown Generated</Typography>
                    <Typography variant="body1" >{file.date}</Typography>
                  </CardContent>
                </Card>
              </React.Fragment>
            )) : <Typography variant="body1" >No files found</Typography>}
          </List>
        </div>

      </div>
    </>
  );
  
  const orgDetails = orgData
  ? Object.entries(organisationKeys).map(([label, key]) => (
      <React.Fragment key={label}>
        <div className='row mb-2'>
          <div className='col-6'>
            <p className='card-text' style={{ margin: '0', fontWeight: 'lighter' }}>{label}</p>
          </div>
          <div className='col-6'>
            <p className='card-text' style={{ margin: '0', fontWeight: 'bold' }}>{orgData[key] ? orgData[key] : "N/A"}</p>
          </div>
        </div>
      </React.Fragment>
    ))
  : null;

  const UserDataInfo = [
    {
      "Username": "username",
      "First Name": "first_name",
      "Last Name": "last_name"
    }
  ];
  
  const userDataDetails = userData
  ? Object.entries(UserDataInfo[0]).map(([label, key]) => (
      <React.Fragment key={label}>
        <div className='row mb-2'>
          <div className='col-6'>
            <p className='card-text' style={{ margin: '0', fontWeight: 'lighter' }}>{label}</p>
          </div>
          <div className='col-6'>
            <p className='card-text' style={{ margin: '0', fontWeight: 'bold' }}>{userData[key] ? userData[key] : "N/A"}</p>
          </div>
        </div>
      </React.Fragment>
    ))
  : null;

  
  
  
  const account = (
    <>
      <div className='d-flex justify-content-between align-items-center '>
        <div className='d-flex flex-row align-items-center'>
          <span className='fs-5 fw-bold'>Account Details</span>
        </div>
      </div>
  
      <div className='row row-cols-2 g-4 py-3'>
        <div className='col'>
          <div className='card h-100' style={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', border: '1px solid #213F99', borderRadius: '10px', backgroundColor: "rgba(241, 241, 241, 0.35)" }}>
            <div className='card-body'>
              <h5 className='card-title fw-bold'>Personal</h5>
              <div className='row row-cols-2 g-4 py-1'>
                <div className='card-body'>
                  {userDataDetails}
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className='col'>
          <div className='card h-100' style={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', border: '1px solid #213F99', borderRadius: '10px', backgroundColor: "rgba(241, 241, 241, 0.35)" }}>
            <div className='card-body'>
              <h5 className='card-title fw-bold'>Organization Details</h5>
              <div className='row row-cols-2 g-4 py-1'>
                <div className='card-body'>
                  {orgDetails}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  


  return (
    <div style={{ display: 'flex', height: 'auto', minHeight: "100vh", fontFamily: 'Manrope, sans-serif' }}>
      {loading && (<LinearProgress sx={{ width: '100%', mb: 2, position: "fixed" }} />)}
      <div className='d-flex flex-column ' style={{ width: 'auto', backgroundColor: '#ffffff', padding: '20px' }}>
      <OverlayTrigger
              placement="right"
              overlay={<Tooltip >Dashboard</Tooltip>}
            >
        <button className='btn my-2' style={{ backgroundColor: activeSection === 'files' ? "#213F99" : "#C7C7C71A",  width: '40px',
                  height: '40px', cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',}} onClick={() => handleSectionClick('files')}>
                    
          {activeSection === 'files' ? <Dashboard /> : <Dashboard_inactive />}
        </button></OverlayTrigger>
        <OverlayTrigger
              placement="right"
              overlay={<Tooltip >Accounts</Tooltip>}
            >
        <button className='btn my-2' style={{ backgroundColor: activeSection === 'account' ? "#213F99" : "#C7C7C71A",  width: '40px',
                  height: '40px',cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center' }} onClick={() => handleSectionClick('account')}>
          {activeSection === 'account' ? <Account_filled /> : <Account />}
        </button></OverlayTrigger>
      </div>
      <div style={{ flex: 1, padding: '20px', backgroundColor: '#fff', boxShadow: '-5px 0px 15px rgba(0, 174, 239, 0.1)' }}>
        {activeSection === 'files' && (
          // Render files component here
          <>
            {filecomponent}
          </>

        )}
        {activeSection === 'account' && (
          // Render dashboard component here
          <>
            {account}
          </>
        )}
      </div>
    </div>

  );
}

export default Listview;

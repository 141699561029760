import React from 'react';
import img1 from '../Images/img1.png';

const Usage = () => {
  return (
    <div className="" style={{ backgroundColor: "#f0f8ff", padding: "5%" }}>
      <h2 className="mb-4 text-center fs-2 fw-semibold">Creating crown now is just 4 Clicks away</h2>
      <div className="row">
        {/* Cards on the left (col-md-9) */}
        <div className="col-md-9">
          <div className="row">
            {/* First Set of Cards (Above) */}
            <div className="col-md-6 mb-4">
              <div className="card h-100" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                <div className="card-body">
                  <h5 className="card-title">Step 1: Upload Files</h5>
                  <p className="card-text">
                    Upload your dental files in STL format, to the Dental AI platform.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="card h-100" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                <div className="card-body">
                  <h5 className="card-title">Step 2: AI Analysis</h5>
                  <p className="card-text">
                    Our advanced AI algorithms analyze the uploaded files to provide accurate predictions and insights.
                  </p>
                </div>
              </div>
            </div>

            {/* Second Set of Cards (Below) */}
            <div className="col-md-6">
              <div className="card h-100" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                <div className="card-body">
                  <h5 className="card-title">Step 3: 3D Model Generation</h5>
                  <p className="card-text">
                    Receive detailed and precise 3D models for dental procedures and planning based on AI analysis.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card h-100" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                <div className="card-body">
                  <h5 className="card-title">Step 4: Review and Adjust</h5>
                  <p className="card-text">
                    Review the generated results and make any necessary adjustments for optimal outcomes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Image on the right (col-md-3) */}
        <div className="col-md-3">
          <img
            src={img1}
            alt="Illustration"
            className="img-fluid rounded"
            style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Usage;

// eslint-disable-next-line
import React, { useEffect, useState, memo, useRef, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import { ClipLoader } from 'react-spinners';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ReactComponent as PrepIcon } from '../assets/Prep.svg';
import { ReactComponent as CrownIcon } from '../assets/Magic.svg';
import { ReactComponent as MySVG } from '../assets/Vector 14.svg';
import { ReactComponent as MySVG1 } from '../assets/Upper jaw.svg';
import { ReactComponent as MySVG2 } from '../assets/Tile.svg';
import { ReactComponent as MySVG3 } from '../assets/Grid.svg';
import { ReactComponent as MySVG4 } from '../assets/Model.svg';
import { ReactComponent as MySVG5 } from '../assets/delete.svg';
import { ReactComponent as MySVG7 } from '../assets/Lower jaw inactive.svg';
import { ReactComponent as MySVG8 } from '../assets/Upper jaw inactive.svg';
import { ReactComponent as MySVG9 } from '../assets/Lower jaw.svg';
import { ReactComponent as MySVG10 } from '../assets/Check.svg';
import { ReactComponent as Delete } from '../assets/Close Icon.svg';
import { ReactComponent as View } from '../assets/Group.svg';
import { ReactComponent as Toolbox } from '../assets/Toolbox.svg';
import { ReactComponent as Smooth } from '../assets/Smooth.svg';
import { ReactComponent as Brush } from '../assets/Proximal.svg';
import { ReactComponent as Margin } from '../assets/Margin.svg';
import { ReactComponent as Visiblity } from '../assets/visibility.svg';
import { ReactComponent as ExportIcon } from '../assets/Export.svg';
import ThreeJSManager from "./Crown Component/ThreeD"
import KdTree from 'static-kdtree'; // Import the KD-tree library
import { toCreasedNormals } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter';
import * as U from "./Crown Component/utils"


const Crown = () => {
  const location = useLocation();
  const { file1, file2, crown, crownFirst, marginCenter, thickness, position, axis, prepView, antaView, inner_surface } = location.state || {};
  const [prep, setprep] = useState(file1)
  const [anta, setanta] = useState(file2)
  const navigate = useNavigate();
  const [brushingPoints, setBrushingPoints] = useState(null);
  const [marginstartpoint, setMarginstartpoint] = useState(null);
  const [marginendpoint, setMarginendpoint] = useState(null);
  const [Adjustmentpanel, setAdjustmentpanel] = useState(true);
  const [selectedOption, setSelectedOption] = useState('medium');
  const [crownView, setcrownView] = useState(false);
  const [tool, setTool] = useState(false);
  const [initialCameraPosition, setInitialCameraPosition] = useState(position ? position : new THREE.Vector3(-42.57305524045056, -53.444090713186604, 9.906063101665028));
  const [showForm, setShowForm] = useState(false)
  const [shoeNewcrown, setshoeNewcrown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [brushing, setBrushing] = useState(false);
  const [margin, setMargin] = useState(false);
  const [isRaycasterActive, setIsRaycasterActive] = useState(false);
  const raycasterSphereRef = useRef(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [crownforrequest, setCrownforrequest] = useState(null);
  const [indexforrequest, setIndexforrequest] = useState(null);
  const [crownarray, setCrownarray] = useState(crown);
  const primaryColor = '#213F99';
  const [category, setCategory] = useState('upper');
  const [marginIndex, setMarginIndex] = useState(null);
  const [smoothpoints, setSmoothpoints] = useState([]);
  const [crownColor, setCrownColor] = useState('#ffffff');
  const [anatomy, setAnatomy] = useState(false);
  const [distalcenter, setdestialcenter] = useState(false);
  const [mesialcenter, setmesialcenter] = useState(false);
  const [center, setcenter] = useState(false);
  const [smooth, setSmooth] = useState(false);
  const [vectorOfAxis, setVectorOfAxis] = useState([]);
  const [grooves, setGrooves] = useState([]);
  const [inner_distance, setInner_distance] = useState(null);
  const [distanceView, setDistanceView] = useState(false);
  const [addsub, setaddsub] = useState(1);
  const [adjList, setAdjList] = useState(null);
  const [brushingRadius, setBrushingRadius] = useState(0.5);
  const [brushingStrength, setBrushingStrength] = useState(0.011);
  const [smootheningRadius, setSmootheningRadius] = useState(0.5);
  const [smootheningStrength, setSmootheningStrength] = useState(0.5);
  const color = useRef('#ffffff');
  const [renderer, setRenderer] = useState(null);
  const [camera, setCamera] = useState(null);
  const [controls, setControls] = useState(null);
  const [scene, setScene] = useState(null);
  const [sliderData, setSliderData] = useState([
    { name: 'Prep', opacity: 1, visible: prepView },
    { name: 'Anta', opacity: 1, visible: antaView },
    // Add Crown files to the sliderData array
    ...crown.map((crownFile, index) => ({
      name: `Crown_${index}`,  // You can customize the naming convention for each Crown file
      opacity: 1,
      visible: true,
    })),
  ]);

  const containerRef = useRef(null);
  const [threeJSManager, setThreeJSManager] = useState(null);

  useEffect(() => {
    console.log("inner_surface", inner_surface)
    if (containerRef.current) {
      const manager = new ThreeJSManager(
        containerRef.current.id,
        initialCameraPosition, color, sliderData, false, crownarray, file1, file2, crown, prepView, antaView, scene, camera, controls
      );
      manager.initRenderer();
      manager.initScene();
      manager.initCamera();
      manager.initControls();
      manager.animate();
      manager.loadAllSTLs();
      setScene(manager.scene);
      setRenderer(manager.renderer);
      setCamera(manager.camera);
      setControls(manager.controls);
      setThreeJSManager(manager);

      return () => {
        manager.cleanup();
      };
    }


  }, []);
  useEffect(() => {
    if (vectorOfAxis.length > 0) {
      const scaleFactor = 2;
      const colors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff]; // Array of 5 colors

      // Iterate through the vectors and create lines
      vectorOfAxis.forEach((arr, index) => {
        {
          const start = arr[0];
          const end = arr[1];

          // Choose a color based on the index
          const color = colors[index];
          const material = new THREE.LineBasicMaterial({ color });

          // Calculate the direction vector and scale it
          const direction = new THREE.Vector3(
            end[0] - start[0],
            end[1] - start[1],
            end[2] - start[2]
          ).multiplyScalar(scaleFactor);

          // Create the new scaled end point
          const scaledEnd = new THREE.Vector3(
            start[0] + direction.x,
            start[1] + direction.y,
            start[2] + direction.z
          );

          const points = [];
          points.push(new THREE.Vector3(start[0], start[1], start[2]));
          points.push(scaledEnd);

          const geometry = new THREE.BufferGeometry().setFromPoints(points);
          const line = new THREE.Line(geometry, material);
          scene.add(line);
        }
      });
    }

    // if(grooves.length > 0){
    //   scene.getObjectByName('crown_0')

    // }
    

    const handleCrownLoading = () => {
      if (scene.getObjectByName('crown_0')) {
        const object = scene.getObjectByName('crown_0');
        const geometry = object.geometry;
        const geometries = U.parseGeometry(geometry, true);
        console.log("adjacent set");
        const faces = geometries[1];
        const adj = U.build_adjacency(geometry, faces);
        if(!adjList){
        setAdjList(adj);
        console.log(adj); // Log adj instead of adjList to see immediate value
        }
      }
    };

    if (threeJSManager) {
      threeJSManager.loadcrowns(crownarray, handleCrownLoading);
    }
  
    // Load crowns and set up callback
    
      
  },
    [threeJSManager,adjList ,crownarray, vectorOfAxis]);



  useEffect(() => {
    const shineSlider = document.getElementById('shine');
    const axisButton = document.getElementById('grid');
    const wireframeButton = document.getElementById('wireframe');
    const viewButton = document.getElementById('defaultview');
    const opacitySliders = document.querySelectorAll('[id^="opacitySlider-"]');
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    


    const handleMouseDown = (event) => {
      if (event.button === 2) {
        return;
      }
      const rect = renderer.domElement.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const point = intersects[0].point;
        const selectedObject = intersects[0].object;
        if (selectedObject.name.match(/\d+$/)) {
          const match = selectedObject.name.match(/\d+$/);
          setCrownforrequest(selectedObject);
          setIndexforrequest(parseInt(match[0]));
        }
        setSelectedCoordinates(point);
        if (raycasterSphereRef.current) {
          scene.remove(raycasterSphereRef.current);
        }

        // Create a new raycaster sphere
        if (brushing || smooth) {
          return;
        }

        if (distanceView) {
          if (selectedObject.name === 'crown_0') {
            const raycasterMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
            const raycasterSphereGeometry = new THREE.SphereGeometry(0.16); // Adjust the size as needed
            const newRaycasterSphere = new THREE.Mesh(raycasterSphereGeometry, raycasterMaterial);
            newRaycasterSphere.position.copy(point);
            raycasterSphereRef.current = newRaycasterSphere;

            // Add the new raycaster sphere to the scene
            scene.add(newRaycasterSphere);
            getDist(point);
          }
        }
        if(margin && event.button === 0){
          const raycasterMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
            const raycasterSphereGeometry = new THREE.SphereGeometry(0.16); // Adjust the size as needed
            const newRaycasterSphere = new THREE.Mesh(raycasterSphereGeometry, raycasterMaterial);
            newRaycasterSphere.position.copy(point);
            raycasterSphereRef.current = newRaycasterSphere;

            // Add the new raycaster sphere to the scene
            scene.add(newRaycasterSphere);
        }
      }
    }
    if (isRaycasterActive) {
      renderer.domElement.addEventListener('mousedown', handleMouseDown);
    }

    const changeShineness = () => {
      if (threeJSManager) {
        threeJSManager.changeShineness();
      }
    };

    const toggleAxes = () => {
      if (threeJSManager) {
        threeJSManager.toggleAxes();
      }
    };

    const toggleWireframe = () => {
      if (threeJSManager) {
        threeJSManager.toggleWireframe();
      }
    };

    const defaultView = () => {
      if (threeJSManager) {
        threeJSManager.defaultView();
      }
    };

    const handleOpacityChange = (index) => {
      if (threeJSManager) {
        threeJSManager.handleOpacityChange(index);
      }
    };

    if (shineSlider) {
      shineSlider.addEventListener('input', changeShineness);
    }

    if (axisButton) {
      axisButton.addEventListener('click', toggleAxes);
    }

    if (wireframeButton) {
      wireframeButton.addEventListener('click', toggleWireframe);
    }

    if (viewButton) {
      viewButton.addEventListener('click', defaultView);
    }

    opacitySliders.forEach((slider, index) => {
      slider.addEventListener('input', () => {
        handleOpacityChange(index);
      });
    });

    return () => {
      if (shineSlider) {
        shineSlider.removeEventListener('input', changeShineness);
      }

      if (axisButton) {
        axisButton.removeEventListener('click', toggleAxes);
      }

      if (wireframeButton) {
        wireframeButton.removeEventListener('click', toggleWireframe);
      }

      if (viewButton) {
        viewButton.removeEventListener('click', defaultView);
      }

      opacitySliders.forEach((slider, index) => {
        slider.removeEventListener('input', () => {
          handleOpacityChange(index);
        });
      });
    };
  }, [threeJSManager, isRaycasterActive, brushing,margin, distanceView]);


  /// tooling use effect
  useEffect(() => {
    if (threeJSManager) {
      controls.noPan = false;
      controls.enablePan = true;
    }
    if (threeJSManager && scene && camera && renderer && (brushing || smooth)) {
      let isSmoothing = false;
      const brushingRadiusSlider = document.getElementById('brushingradius');
      const brushingStrengthSlider = document.getElementById('brushingstrength');
      const smootheningRadiusSlider = document.getElementById('smootheningradius');
      const smootheningStrengthSlider = document.getElementById('smootheningstrength');


      const handleBrushingRadiusChange = (event) => {
        if (event.target && event.target.value !== undefined) {
          console.log("radius", event.target.value);
          setBrushingRadius(event.target.value);
        } else {
          console.error('Event target or its value is null/undefined');
        }
      };
      const handleSmootheningStrengthChange = (event) => {
        if (event.target && event.target.value !== undefined) {
          console.log("strength", event.target.value);
          setSmootheningStrength(event.target.value);
        } else {
          console.error('Event target or its value is null/undefined');
      }}

      // Function to handle brushing strength change
      const handleBrushingStrengthChange = (event) => {
        if (event.target && event.target.value !== undefined) {
          console.log("strength", event.target.value);
          setBrushingStrength(event.target.value);
        } else {
          console.error('Event target or its value is null/undefined');
        }
      };

      const handleSmootheningRadiusChange = (event) => {
        if (event.target && event.target.value !== undefined) {
          console.log("strength", event.target.value);
          setSmootheningRadius(event.target.value);
        } else {
          console.error('Event target or its value is null/undefined');
        }
      }

      // Ensure the elements exist before adding event listeners
      if (brushingRadiusSlider && brushingStrengthSlider) {
        brushingRadiusSlider.addEventListener('input', handleBrushingRadiusChange);
        brushingStrengthSlider.addEventListener('input', handleBrushingStrengthChange);
        smootheningRadiusSlider.addEventListener('input', handleSmootheningRadiusChange);
        smootheningStrengthSlider.addEventListener('input', handleSmootheningStrengthChange);
      } else {
        console.error('One or both sliders are null/undefined');
      }
      // Set up event listeners
      renderer.domElement.addEventListener('mousedown', startSmoothing);
      renderer.domElement.addEventListener('mousemove', updateSmoothing);
      renderer.domElement.addEventListener('mouseup', stopSmoothing);

      function startSmoothing(event) {
        isSmoothing = true;
        updateSmoothing(event);
      }

      function stopSmoothing() {
        isSmoothing = false;
      }

      function updateSmoothing(event) {
        if (!isSmoothing) return;
        if (event.button === 0) {
          if (event.ctrlKey) {
            controls.noPan = true;
            controls.enablePan = false;
            const mouse = new THREE.Vector2();
            const raycaster = new THREE.Raycaster();

            // Calculate mouse position in normalized device coordinates
            const rect = renderer.domElement.getBoundingClientRect();
            mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

            // Update the picking ray with the camera and mouse position
            raycaster.setFromCamera(mouse, camera);

            // Calculate objects intersecting the picking ray
            const intersects = raycaster.intersectObjects(scene.children);

            if (intersects.length > 0) {
              const intersectionPoint = intersects[0].point;
              if (brushing) {
                addFace(addsub, intersectionPoint);
              }
              if (smooth) {
                smoothenFrontend(intersectionPoint);
              }
            }
          }
          else {
            controls.noPan = false;
            controls.enablePan = true;
          }
        }


      }


      // Clean up event listeners when component unmounts
      return () => {
        renderer.domElement.removeEventListener('mousedown', startSmoothing);
        renderer.domElement.removeEventListener('mousemove', updateSmoothing);
        renderer.domElement.removeEventListener('mouseup', stopSmoothing);
        brushingRadiusSlider.removeEventListener('input', handleBrushingRadiusChange);
        brushingStrengthSlider.removeEventListener('input', handleBrushingStrengthChange);
        smootheningRadiusSlider.removeEventListener('input', handleSmootheningRadiusChange);
        smootheningStrengthSlider.removeEventListener('input', handleSmootheningStrengthChange);
      };
    }
  }, [threeJSManager, scene, camera, renderer ,brushing, addsub, smooth, brushingRadius, brushingStrength,smootheningStrength ,smootheningRadius]);

  useEffect(() => {
    if (threeJSManager && scene && camera && renderer && (brushing || smooth)) {
      let hoverCircle = null;

      const brushingRadiusSlider = document.getElementById('brushingradius');
      const handleBrushingRadiusChange = (event) => {
        if (event.target && event.target.value !== undefined) {
          console.log("radius", event.target.value);
          setBrushingRadius(event.target.value);
        } else {
          console.error('Event target or its value is null/undefined');
        }
      };
      brushingRadiusSlider.addEventListener('input', handleBrushingRadiusChange);
  
      // Create a circle geometry
      let radius = 0.5;
      if(brushing){
        radius = brushingRadius
      }
      if(smooth){
        radius = smootheningRadius
      }
      const circleGeometry = new THREE.CircleGeometry(radius, 32);
      const circleMaterial = new THREE.MeshBasicMaterial({ 
        color: 0xff0000,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.1,
        depthTest: false,
      });
      hoverCircle = new THREE.Mesh(circleGeometry, circleMaterial);
      hoverCircle.visible = false;
      hoverCircle.renderOrder = 2;
      scene.add(hoverCircle);
  
      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();
  
      


      function onMouseMove(event) {
        // Calculate mouse position in normalized device coordinates
        const rect = renderer.domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
  
        // Update the picking ray with the camera and mouse position
        raycaster.setFromCamera(mouse, camera);
  
        // Calculate objects intersecting the picking ray
        const intersects = raycaster.intersectObjects(scene.children);
  
        // Check if we're hovering over Crown_0
        const crownIntersect = intersects.find(intersect => intersect.object.name === 'crown_0');
  
        if (crownIntersect) {
          // We're hovering over Crown_0
          hoverCircle.position.copy(crownIntersect.point);
          hoverCircle.lookAt(crownIntersect.face.normal.add(crownIntersect.point));
          hoverCircle.visible = true;
        } else {
          // We're not hovering over Crown_0
          hoverCircle.visible = false;
        }
  
        // Render the scene
        renderer.render(scene, camera);
      }
  
      // Add event listener
      renderer.domElement.addEventListener('mousemove', onMouseMove);
  
      // Clean up function
      return () => {
        renderer.domElement.removeEventListener('mousemove', onMouseMove);
        if (hoverCircle) {
          scene.remove(hoverCircle);
          hoverCircle.geometry.dispose();
          hoverCircle.material.dispose();
        }
      };
    }
  }, [threeJSManager, scene, camera, renderer,brushing,brushingRadius, smooth, smootheningRadius]);

  function addFace(add, point) {
    if (scene) {
      console.log(brushingRadius);
      const object = scene.getObjectByName('crown_0');
      if (object && object.geometry) {
        const geometry = object.geometry;

        const geometries = U.parseGeometry(geometry)

        const normal = geometries[2]
        const vertices = geometries[0]

        const kdtree = new KdTree(vertices);

        // Define the selected point and deformation parameters
        const selectedPoint = new THREE.Vector3(point.x, point.y, point.z);
        let strength = brushingStrength ? brushingStrength : 1;

        // Function to compute smooth falloff
        const smoothFalloff = (distance, maxDistance) => {
          const x = distance / Math.abs(maxDistance);
          return 1 - (3 * x * x - 2 * x * x * x);

        };

        // Function to get neighbors using KD-tree
        const
          getNeighbors = (vertexIndex) => {
            return kdtree.knn(vertices[vertexIndex], 5); // Get 6 nearest neighbors
          };

        // Get the normal at the selected point
        const start_ind = kdtree.knn([selectedPoint.x, selectedPoint.y, selectedPoint.z], 1)[0];
        const selectedPointNormal = new THREE.Vector3(add * normal.getX(start_ind), add * normal.getY(start_ind), add * normal.getZ(start_ind));

        if (selectedPointNormal.length() > 0) {
          selectedPointNormal.normalize();
        }


        const knnNum = brushingRadius ? brushingRadius : 3;
        const noofneigh = U.knn_bfsbrushing(geometry,adjList ,brushingRadius, start_ind, geometries[1],vertices);

        let maxneighbdist = 0

        noofneigh.forEach((ind) => {
          const dist = new THREE.Vector3(...vertices[ind]).distanceTo(selectedPoint)
          maxneighbdist = Math.max(dist, maxneighbdist)
        })
      //  maxneighbdist = brushingRadius
        
        // const maxneighbdist = selectedPoint.distanceTo(new THREE.Vector3(...vertices[noofneigh[knnNum-1]]))
        const position = geometry.attributes.position;
        noofneigh.forEach((ind) => {
          const vertex = new THREE.Vector3(...vertices[ind]);
          const distance = vertex.distanceTo(selectedPoint);
          const deformationFactor = smoothFalloff(distance, maxneighbdist);
          // Move vertex in the direction of the selected point's normal
          const moveVector = selectedPointNormal.clone().multiplyScalar(deformationFactor * strength); // Scale by maxDistance

          vertex.add(moveVector);

          geometry.attributes.position.setXYZ(ind, vertex.x, vertex.y, vertex.z)
          console.log('set')

        })

        // Mark the position attribute as needing an update
        position.needsUpdate = true;

        // Recompute normals and bounding sphere for smooth rendering
        geometry.computeVertexNormals();
        geometry.computeBoundingSphere();

        // Ensure object matrices are up to date
        object.updateMatrix();
        object.updateMatrixWorld();

        // Log the final state
        console.log('Final position array:', [...position.array]);

        console.log('Mesh modification completed');
        if (renderer && camera) {
          renderer.render(scene, camera);
        } else {
          console.warn('Renderer or camera not available');
        }

      } else {
        console.error('Object or geometry not found');
      }
    } else {
      console.error('Scene not available');
    }
  };



  // function addFace(add, point) {
  //   if (scene) {
  //     console.log(brushingRadius);
  //     const object = scene.getObjectByName('crown_0');
  //     if (object && object.geometry) {
  //       const geometry = object.geometry;

  //       const geometries = U.parseGeometry(geometry)

  //       const normal = geometries[2]
  //       const vertices = geometries[0]

  //       const kdtree = new KdTree(vertices);

  //       // Define the selected point and deformation parameters
  //       const selectedPoint = new THREE.Vector3(point.x, point.y, point.z);
  //       let strength = brushingStrength ? brushingStrength : 1;

  //       // Function to compute smooth falloff
  //       const smoothFalloff = (distance, maxDistance) => {
  //         const x = distance / Math.abs(maxDistance);
  //         return 1 - (3 * x * x - 2 * x * x * x);

  //       };

  //       // Function to get neighbors using KD-tree
  //       const
  //         getNeighbors = (vertexIndex) => {
  //           return kdtree.knn(vertices[vertexIndex], 5); // Get 6 nearest neighbors
  //         };

  //       // Get the normal at the selected point
  //       const start_ind = kdtree.knn([selectedPoint.x, selectedPoint.y, selectedPoint.z], 1)[0];
  //       const selectedPointNormal = new THREE.Vector3(add * normal.getX(start_ind), add * normal.getY(start_ind), add * normal.getZ(start_ind));

  //       if (selectedPointNormal.length() > 0) {
  //         selectedPointNormal.normalize();
  //       }


  //       const knnNum = brushingRadius ? brushingRadius : 3;
  //       const noofneigh = U.knn_bfs(geometry, knnNum, start_ind, geometries[1]);

  //       let maxneighbdist = 0

  //       noofneigh.forEach((ind) => {
  //         const dist = new THREE.Vector3(...vertices[ind]).distanceTo(selectedPoint)
  //         maxneighbdist = Math.max(dist, maxneighbdist)
  //       })

        
  //       // const maxneighbdist = selectedPoint.distanceTo(new THREE.Vector3(...vertices[noofneigh[knnNum-1]]))
  //       const position = geometry.attributes.position;
  //       noofneigh.forEach((ind) => {
  //         const vertex = new THREE.Vector3(...vertices[ind]);
  //         const distance = vertex.distanceTo(selectedPoint);
  //         const deformationFactor = smoothFalloff(distance, maxneighbdist);
  //         // Move vertex in the direction of the selected point's normal
  //         const moveVector = selectedPointNormal.clone().multiplyScalar(deformationFactor * strength); // Scale by maxDistance

  //         vertex.add(moveVector);

  //         geometry.attributes.position.setXYZ(ind, vertex.x, vertex.y, vertex.z)
  //         console.log('set')

  //       })

  //       // Mark the position attribute as needing an update
  //       position.needsUpdate = true;

  //       // Recompute normals and bounding sphere for smooth rendering
  //       geometry.computeVertexNormals();
  //       geometry.computeBoundingSphere();

  //       // Ensure object matrices are up to date
  //       object.updateMatrix();
  //       object.updateMatrixWorld();

  //       // Log the final state
  //       console.log('Final position array:', [...position.array]);

  //       console.log('Mesh modification completed');
  //       if (renderer && camera) {
  //         renderer.render(scene, camera);
  //       } else {
  //         console.warn('Renderer or camera not available');
  //       }

  //     } else {
  //       console.error('Object or geometry not found');
  //     }
  //   } else {
  //     console.error('Scene not available');
  //   }
  // };

  function smoothenFrontend(point) {
    if (scene) {
      const object = scene.getObjectByName('crown_0');
      if (object && object.geometry) {
        const geometry = object.geometry;
        const geometries = U.parseGeometry(geometry, false)
        const vertices = geometries[0]
        const position = geometry.attributes.position;

        // Set parameters
        let strength = smootheningStrength; // Default strength
        const iterations = 1;
        const neighborsCount = smootheningRadius;

        // Derive start point and index
        const T0 = new KdTree(vertices)
        const startInd = T0.knn([point.x, point.y, point.z], 1)[0];

        // Derive only connected points to localize the effect of deformation
        const result = U.knn_bfsbrushing(geometry,adjList ,smootheningRadius, startInd, geometries[1],vertices,false,true);
        const connectedNeighbours = result[0];
        const adjacency = result[1];

        // Iterate
        for (let iter = 0; iter < iterations; iter++) {
          connectedNeighbours.forEach((i) => {
            const currentPos = new THREE.Vector3(
              position.getX(i),
              position.getY(i),
              position.getZ(i)
            );
            
            // const neighbors = U.nRingNeighbors(adjacency, i, 1);
            const neighbors = adjacency[i];
            const avgPos = new THREE.Vector3();
            neighbors.forEach((neighborIndex) => {
              avgPos.add(new THREE.Vector3(
                position.getX(neighborIndex),
                position.getY(neighborIndex),
                position.getZ(neighborIndex)
              ));
            });
            avgPos.divideScalar(neighbors.size);

            // Calculate new position as weighted average
            const newPos = new THREE.Vector3().lerpVectors(currentPos, avgPos, strength);

            // Set the new position
            position.setXYZ(i, newPos.x, newPos.y, newPos.z);
          });
        }

        // Mark the position attribute as needing an update
        position.needsUpdate = true;

        // Update geometry to recalculate normals if necessary
        geometry.computeVertexNormals();
        toCreasedNormals(geometry, (90 / 180) * Math.PI);

        // Ensure object matrices are up to date
        object.updateMatrix();
        object.updateMatrixWorld();

        console.log('Smoothing completed');
        setSmoothpoints([]);

        // Force a re-render
        if (renderer && camera) {
          renderer.render(scene, camera);
        } else {
          console.warn('Renderer or camera not available');
        }
      } else {
        console.error('Object or geometry not found');
      }
    } else {
      console.error('Scene not available');
    }
  }

  


  function getDist(point) {
    if (inner_surface) {
      const dist = U.distance([point.x, point.y, point.z], inner_surface);
      setInner_distance(dist);
    }
  }

  const MarginfrontEnd = () => {
    if (scene) {
      const object = scene.getObjectByName('crown_0');
      if (object && object.geometry) {
        console.log('Object found:', object);
        const geometry = object.geometry;
        const position = geometry.attributes.position;
        const normal = geometry.attributes.normal;

        // Extract vertex positions
        const vertices = [];
        for (let i = 0; i < position.count; i++) {
          vertices.push([position.getX(i), position.getY(i), position.getZ(i)]);
        }

        // Create KD-tree
        const kdtree = new KdTree(vertices);

        // Define the start and end points
        const startPoint = new THREE.Vector3(marginstartpoint[0], marginstartpoint[1], marginstartpoint[2]);
        const endPoint = new THREE.Vector3(marginendpoint[0], marginendpoint[1], marginendpoint[2]);
        console.log('Start point:', startPoint);
        console.log('End point:', endPoint);

        // Function to get neighbors using KD-tree
        const getNeighbors = (vertex, k) => {
          const result = kdtree.knn(vertex, k);
          if (typeof result === 'number') {
            return [[result, 0]];
          }
          return result;
        };

        // Find the nearest vertex to the start point and get its normal
        const startNeighbors = getNeighbors([startPoint.x, startPoint.y, startPoint.z], 10);
        let startNormal = new THREE.Vector3(0, 0, 0);
        for (let i = 0; i < startNeighbors.length; i++) {
          const neighborIndex = startNeighbors[i][0];
          startNormal.add(new THREE.Vector3(
            normal.getX(neighborIndex),
            normal.getY(neighborIndex),
            normal.getZ(neighborIndex)
          ));
        }
        startNormal.normalize();

        if (startNormal.lengthSq() < 0.001) {
          // If we still don't have a valid normal, use the direction from start to end
          startNormal = endPoint.clone().sub(startPoint).normalize();
        }

        console.log('Start normal:', startNormal);

        // Calculate length of deformation
        const length = startPoint.distanceTo(endPoint);
        console.log('Deformation length:', length);

        // Deformation parameters
        const influenceRadius = Math.max(length / 2, 5.0); // Adjust based on model scale
        const strength = 1.0; // Adjust as needed
        console.log('Influence radius:', influenceRadius);
        console.log('Strength:', strength);

        // Function to compute smooth falloff
        const smoothFalloff = (distance, maxDistance) => {
          const x = distance / maxDistance;
          return 1 - (3 * x * x - 2 * x * x * x);
        };

        // Apply deformation
        let deformedVertexCount = 0;
        const newVertices = vertices.map((v, i) => {
          const vertex = new THREE.Vector3(...v);
          const distanceToStart = vertex.distanceTo(startPoint);

          if (distanceToStart < influenceRadius) {
            const deformationFactor = smoothFalloff(distanceToStart, influenceRadius);
            const moveVector = startNormal.clone().multiplyScalar(deformationFactor * strength * length);
            vertex.add(moveVector);
            deformedVertexCount++;

            // Apply simple smoothing
            const neighbors = getNeighbors(v, 6);
            let avgPos = new THREE.Vector3(0, 0, 0);
            let neighborCount = 0;
            neighbors.forEach(neighbor => {
              if (Array.isArray(neighbor)) {
                avgPos.add(new THREE.Vector3().fromArray(vertices[neighbor[0]]));
                neighborCount++;
              }
            });
            if (neighborCount > 0) {
              avgPos.divideScalar(neighborCount);
              vertex.lerp(avgPos, 0.3); // Blend with the average position
            }

            return [vertex.x, vertex.y, vertex.z];
          }

          return v;
        });

        console.log('Deformed vertex count:', deformedVertexCount);

        // Update vertex positions
        for (let i = 0; i < newVertices.length; i++) {
          position.setXYZ(i, newVertices[i][0], newVertices[i][1], newVertices[i][2]);
        }

        // Mark the position attribute as needing an update
        position.needsUpdate = true;

        // Recompute normals and bounding sphere for smooth rendering
        geometry.computeVertexNormals();
        geometry.computeBoundingSphere();

        // Ensure object matrices are up to date
        object.updateMatrix();
        object.updateMatrixWorld();

        console.log('Mesh modification completed');
        setMarginstartpoint([]);
        setMarginendpoint([]);

        // Force a re-render
        if (renderer && camera) {
          renderer.render(scene, camera);
        } else {
          console.warn('Renderer or camera not available');
        }

      } else {
        console.error('Object or geometry not found');
      }
    } else {
      console.error('Scene not available');
    }
  };

  const handleToggleVisibility = (index) => {
    if (threeJSManager) {
      const updatedSliderData = [...sliderData];
      updatedSliderData[index].visible = !updatedSliderData[index].visible;
      setSliderData(updatedSliderData);
      threeJSManager.handleToggleVisibilityClick(index);
    }
  };

  const updateCrownArrayBeforeExport = (callback) => {
    if (scene) {
      const exporter = new STLExporter();
      const newCrownArray = crownarray.map((crown, index) => {
        const object = scene.getObjectByName(`crown_${index}`);
        if (object) {
          const stlString = exporter.parse(object);
          const blob = new Blob([stlString], { type: 'text/plain' });
          return new File([blob], `crown_${index}.stl`);
        }
        return crown;
      });
      setCrownarray(newCrownArray);
      console.log('Crown array updated before export:', newCrownArray);
      if (callback) callback(newCrownArray);
    }
  };

  const handlesave = () => {
    updateCrownArrayBeforeExport((updatedCrownArray) => {
      navigate("/export", {
        state: {
          file1,
          file2,
          crown,
          crownFirst,
          marginCenter,
          thickness,
          position,
          axis,
          prepView,
          antaView,
          exportelements: updatedCrownArray
        }
      });
    });
  };

  const handleAlertClose = () => {
    setError(false);
  };
  const setdefaultview = () => {
    setInitialCameraPosition(position ? position : new THREE.Vector3(2.6, -17, 70));
  }
  const anatomyGen = async () => {
    if (center === null || distalcenter === null || mesialcenter === null) {
      alert('Please select all the points');
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('prep', prep);
      formData.append('anta', anta);
      formData.append('file1', crownarray[indexforrequest]);
      formData.append('center', JSON.stringify(center));
      formData.append('distal', JSON.stringify(distalcenter));
      formData.append('mesial', JSON.stringify(mesialcenter));
      formData.append('category', JSON.stringify(category));

      const response = await fetch('https://api.dentalai.ai/anatomy/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const vectorOfAxis = response.headers.get('axis');
        const groovesheaders = response.headers.get('grooves');
        const groovesarr = JSON.parse(groovesheaders);
        console.log(groovesarr);
        setGrooves(groovesarr);
        const axis = JSON.parse(vectorOfAxis);
        const blob = await response.blob();
        const crownProcessed = new File([blob], 'processed_mesh.stl');
        const updatedCrown = [...crownarray];
        updatedCrown[indexforrequest] = crownProcessed;

        setCrownarray(updatedCrown);
        setanatomycenter(null);
        setdistal(null);
        setmesial(null);
        setVectorOfAxis(axis);
        // Handle the vectorOfAxis as needed, e.g., update state or log it
        setLoading(false);

      } else {
        setError(true)
        setLoading(false);

      }
    } catch (error) {
      setError(true)
      setLoading(false);

    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const showCrown = () => {
    setcrownView(!crownView);
  }
  const showTool = () => {
    setTool(!tool);
  }
  const brushingTool = () => {
    if (!brushing) {
      setIsRaycasterActive(true);
    }

    setBrushing(!brushing);
    setMargin(false);
  }
  const anatomyTool = () => {
    if (!anatomy) {
      setIsRaycasterActive(true);
    }

    setAnatomy(!anatomy);
    setMargin(false);
  }
  const marginTool = () => {
    if (!margin) {
      setIsRaycasterActive(true);
    }

    setMargin(!margin);
    setBrushing(false);
  }
  const setBrushingpoint = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }

    setBrushingPoints([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }
  const smoothTool = () => {
    if (!smooth) {
      setIsRaycasterActive(true);
    }
    setSmooth(!smooth);
    setMargin(false);
  }
  const setanatomycenter = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }

    setcenter([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }
  const setdistal = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }

    setdestialcenter([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }
  const setmesial = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }

    setmesialcenter([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }
  const handleCategorychange = (event) => {
    setCategory(event.target.value);
  }

  const setstart = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }
    setMarginIndex(indexforrequest);
    setMarginstartpoint([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }
  const setend = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }
    setMarginendpoint([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }

  const brushingView = (
    <>
      <div className="p-3 text-center position-absolute rounded-4" style={{
        display: brushing ? 'block' : 'none',
        minWidth: "15vw", zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
      }}>
        <div className='mb-3 d-flex justify-content-between align-items-center' style={{ color: 'black' }}>
          <div>Brush</div>
          <div onClick={brushingTool}><Delete /></div>
        </div>

        <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
          <div className='d-flex justify-content-between flex-column' style={{ color: 'black' }}>
            <div>
              Radius
            </div>
            <input
              style={{ display: 'flex' }}
              type="range"
              id={'brushingradius'}
              min="0.05"
              max="5"
              step="0.1"
              defaultValue="0.5"
            />
            <div>
              Strength
            </div>

            <input
              style={{ display: 'flex' }}
              type="range"
              id={`brushingstrength`}
              min="0.001"
              max="0.07"
              step="0.001"
              defaultValue="0.007"
            />
          </div>

        </ul>
        <hr style={{ width: "100%", border: "1px solid #000" }} />
        <div className='mt-3 rounded' style={{
          cursor: "pointer",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: addsub === 1 ? primaryColor : "#ffffff",
          color: addsub === 1 ? "#ffffff" : "black",
          border: "1px solid #1F555A",
          transition: 'background-color 0.3s ease',
        }} onClick={() => { setaddsub(1) }}>Add</div>

        <div className='mt-3 rounded' style={{
          cursor: "pointer",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: addsub === -1 ? primaryColor : "#ffffff",
          color: addsub === -1 ? "#ffffff" : "black",
          border: "1px solid #1F555A",
          transition: 'background-color 0.3s ease',
        }} onClick={() => { setaddsub(-1) }}>Sub</div>
      </div>
    </>
  );


  const anatomyView = (
    <>
      <div className="p-3 text-center position-absolute rounded-4" style={{
        display: anatomy ? 'block' : 'none',
        minWidth: "20vw", zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
      }}>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <div>Anatomy</div>
          <div onClick={anatomyTool}><Delete /></div>
        </div>

        <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
          <div className='d-flex justify-content-between flex-column'>
            <select
              className="form-select text-light my-2"
              style={{
                backgroundColor: primaryColor,

              }}
              value={category}
              onChange={handleCategorychange}
            >
              <option value="upper">Upper Jaw</option>
              <option value="lower">Lower Jaw</option>
            </select>
            <div className="d-flex flex-column align-items-center" style={{ textAlign: "center" }}>
              <div className='d-flex flex-row align-items-center'>
                <div className='d-flex flex-column me-3'>
                  <div>Center</div>
                  <div>Mesial Center</div>
                  <div>Distal Center</div>
                </div>
                <div className='d-flex flex-column ms-3'>
                  <div
                    style={{
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    {!center ? <MySVG10 onClick={setanatomycenter} /> : <MySVG5 onClick={() => { setcenter(null) }} />}
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    {!mesialcenter ? <MySVG10 onClick={setmesial} /> : <MySVG5 onClick={() => { setmesialcenter(null) }} />}
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    {!distalcenter ? <MySVG10 onClick={setdistal} /> : <MySVG5 onClick={() => { setdestialcenter(null) }} />}
                  </div>
                </div>
              </div>
            </div>




          </div>
        </ul>
        <hr style={{ width: "100%", border: "1px solid #000" }} />
        <div className='mt-3 rounded' style={{
          cursor: "pointer",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: primaryColor,
          color: '#fff',
          border: "1px solid #1F555A",
          transition: 'background-color 0.3s ease',
        }} onClick={anatomyGen} >Process</div>

      </div>
    </>
  )
  const marginView = (
    <>
      <div className="p-3 text-center position-absolute rounded-4" style={{
        display: margin ? 'block' : 'none',
        minWidth: "15vw", zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
      }}>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <div>Margin</div>
          <div onClick={marginTool}><Delete /></div>
        </div>

        <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
          <div className='d-flex justify-content-between flex-column'>
            <select
              className="form-select text-light my-2"
              style={{
                backgroundColor: primaryColor,
                width: "auto",

              }}
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
            <div className="d-flex flex-row " style={{ textAlign: "center", justifyContent: "space-between" }}>
              <div className='me-3'>Start Point</div>
              <div className='ms-3'
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
              >{!marginstartpoint ? <MySVG10 onClick={setstart} /> : <MySVG5 onClick={() => { setMarginstartpoint(null) }} />}</div>


            </div>
            <div className="d-flex flex-row " style={{ textAlign: "center", justifyContent: "space-between" }}>
              <div className='me-3'>End Point</div>
              <div className='ms-3'
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
              >{!marginendpoint ? <MySVG10 onClick={setend} /> : <MySVG5 onClick={() => { setMarginendpoint(null) }} />}</div>


            </div>

          </div>
        </ul>
        <hr style={{ width: "100%", border: "1px solid #000" }} />
        <div className='mt-3 rounded-3' style={{
          cursor: "pointer",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: primaryColor,
          color: '#fff',
          border: "1px solid #1F555A",
          transition: 'background-color 0.3s ease',
        }} onClick={MarginfrontEnd} >Process</div>

      </div>
    </>
  )
  const distTool = () => {
    if (!isRaycasterActive) {
      setIsRaycasterActive(true);
    }
    setDistanceView(!distanceView);

  }
  const calculatDistanceView = (
    <>
      <div className="p-3 text-center position-absolute rounded-4" style={{
        display: distanceView ? 'block' : 'none',
        minWidth: "15vw", zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
      }}>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <div>Thickness Tool</div>
          <div onClick={distTool}><Delete /></div>
        </div>

        <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
          <div className='d-flex justify-content-between flex-column'>
            <div className="d-flex flex-row justify-content-center mt-2">
              Thickness: {inner_distance ? <div > {parseFloat(inner_distance).toFixed(4)} </div> : 0} mm
            </div>
          </div>
        </ul>
        <hr style={{ width: "100%", border: "1px solid #000" }} />




      </div>
    </>


  )

  const prepselection = (
    <div className="p-3 text-center position-absolute rounded-4" style={{
      display: crownView ? 'block' : 'none',
      minWidth: "15vw", zIndex: "4", background: "rgba(255, 255, 255, 1)", top: "15%", left: "-160%", transform: "translate(-50%, -50%)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
    }}>

      <div className='mb-3 d-flex justify-content-between align-items-center'>
        <div>Crown Properties</div>
        <div onClick={showCrown}><Delete /></div>
      </div>

      <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
        {crown.map((item, index) => (
          <div key={index} className='d-flex flex-column'>
            <div className="d-flex flex-row " style={{ justifyContent: "space-between" }}>
              <div className='mx-3'>Crown {index}</div>
              <div className='mx-3' id={`toggleVisibilityButton-${index + 2}`}
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onClick={() => handleToggleVisibility(index + 2)}><Visiblity /></div>
            </div>
            <div className='d-flex flex-row'>
              <input
                style={{ display: 'flex' }}
                type="range"
                id={`opacitySlider-${index}`}
                min="0.1"
                max="1"
                step="0.01"
                defaultValue="1"
              />


            </div>
            <div className='d-flex flex-row'>
              <input
                style={{ display: 'none' }}
                type="range"
                id={`shine`}
                min="0.1"
                max="1"
                step="0.01"
                defaultValue="1"
              />


            </div>
            {/* <ChromePicker color={crownColor} ref={color} onChangeComplete={handleColorChange} /> */}
          </div>
        ))}
      </ul>

    </div>
  );
  const statusbar = (
    <>
      <>
        <div className="d-flex py-2 rounded" style={{ zIndex: "2", background: "rgba(144, 200, 224, 0.3)", width: "18vw", transform: "translate(50%, -50%)", boxShadow: "0 2px 4px rgba(144, 200, 224, 0.4)", justifyContent: "space-evenly" }}>
          <div className=" d-flex flex-column"><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px', color: '#fff', transition: 'background-color 0.3s ease', backgroundColor: 'rgba(144, 238, 144, 0.5)', borderRadius: '50%' }}><PrepIcon /></div>Prep</div>
          <div className=" d-flex flex-column"><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px', color: '#fff', transition: 'background-color 0.3s ease', backgroundColor: 'rgba(144, 238, 144, 0.5)', borderRadius: '50%' }}><CrownIcon /></div>Crown</div>
          <div className=" d-flex flex-column"><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px', color: '#fff', transition: 'background-color 0.3s ease' }}><ExportIcon /></div>Export</div>
        </div>
      </>
    </>
  )
  const smoothView = (
    <>
      <div className="p-3 text-center position-absolute rounded-4" style={{
        display: smooth ? 'block' : 'none',
        minWidth: "15vw", zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
      }}>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <div>Smooth</div>
          <div onClick={smoothTool}><Delete /></div>
        </div>

        <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
          <div className='d-flex justify-content-between flex-column'>
            <div className="d-flex flex-row " style={{ textAlign: "center", justifyContent: "space-between" }}>
              <div className='d-flex justify-content-between flex-column' style={{ color: 'black' }}>
                <div>
                  Radius
                </div>
                <input
                  style={{ display: 'flex' }}
                  type="range"
                  id={'smootheningradius'}
                  min="0.05"
                  max="5"
                  step="0.1"
                  defaultValue="0.5"
                />

<div>
              Strength
            </div>

            <input
              style={{ display: 'flex' }}
              type="range"
              id={`smootheningstrength`}
              min="0.001"
              max="1"
              step="0.05"
              defaultValue="0.5"
            />
              </div>



            </div>

          </div>
        </ul>
        <hr style={{ width: "100%", border: "1px solid #000" }} />


      </div>
    </>
  )
  const tooling = (
    <div
      className="p-3 text-center position-absolute rounded-4"
      style={{
        display: tool ? 'block' : 'none',
        minWidth: '5vw',
        zIndex: '4',
        background: 'rgba(255, 255, 255, 1)',
        top: '35%',
        left: '-160%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 0 2px 2px rgba(144, 200, 224, 0.4)',
      }}
    >
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>Tooling</div>
        <div onClick={showTool}>
          <Delete />
        </div>
      </div>

      <ul
        className="list-group d-flex flex-wrap align-items-center justify-content-center p-2 rounded-3"
        style={{ background: 'rgba(144, 200, 224, 0.3)' }}
      >
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-column align-items-center mx-4 mb-3" style={{ width: '80px' }}>
            <button
              className="rounded"
              onClick={brushingTool}
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #1F555A',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Brush />
            </button>
            <div>Brush</div>
          </div>
          <div className="d-flex flex-column align-items-center mx-4 mb-3" style={{ width: '80px' }}>
            <button
              id="prep"
              className="rounded"
              onClick={marginTool}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #1F555A',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Margin style={{ fill: '#ffffff' }} />
            </button>
            <div>Margin</div>
          </div>
          <div className="d-flex flex-column align-items-center mx-4 mb-3" style={{ width: '80px' }}>
            <button
              id="prep"
              className="rounded"
              onClick={anatomyTool}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #1F555A',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Margin style={{ fill: '#ffffff' }} />
            </button>
            <div>Anatomy</div>
          </div>
          <div className="d-flex flex-column align-items-center mx-4 mb-3" style={{ width: '80px' }}>
            <button
              id="prep"
              className="rounded"
              onClick={smoothTool}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #1F555A',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Smooth style={{ fill: '#ffffff' }} />
            </button>
            <div>Smooth</div>
          </div>
          <div className="d-flex flex-column align-items-center mx-4 mb-3" style={{ width: '80px' }}>
            <button
              className="rounded"
              onClick={distTool}
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #1F555A',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Brush />
            </button>
            <div>Thickness Tool</div>
          </div>
        </div>
      </ul>
    </div>
  );
  const adjustment = (
    <div>
      <div className="p-3 text-center position-absolute rounded-4" style={{ zIndex: "2", background: "rgba(144, 200, 224, 0.3)", top: "45%", right: "10%", transform: "translate(50%, -50%)", boxShadow: "0 2px 4px rgba(144, 200, 224, 0.4)", }}>
        <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={
          {
            cursor: 'pointer',
            transition: "transform 1s ease"

          }
        }
        // onClick={showPanel}
        >
          <MySVG className='mb-1' />
          <MySVG className='mb-1' />
        </div>
        {/* Move prepview && prepselection inside the adjustment panel */}
        {prepselection}
        {tooling}
        {Adjustmentpanel && (<>
          <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{ background: "rgba(255,255,255,1" }}>
            {/* Render prepview */}


            <div>Crown</div>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip >Adjust Crowns Visiblity</Tooltip>}
            >
              <button id="prep" className='rounded' onClick={showCrown} title='Use this button to Change prep selection' style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: "1px solid #1F555A",
                transition: 'background-color 0.3s ease',
              }}
              >
                <MySVG4 />
              </button>
            </OverlayTrigger>

          </div>

          <hr style={{ width: "100%", border: "1px solid #000" }} />

          <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3 ' style={{ background: "rgba(255,255,255,1" }}>
            {/* Model 0 */}
            <div className=" container ">
              <label className="container" style={{ fontFamily: 'Manrope, sans-serif' }}>{sliderData[0].name}</label>
              <OverlayTrigger

                placement="left"
                overlay={<Tooltip >Toggle Prep/Anta visibility</Tooltip>}
              >
                <div
                  className="container rounded-3"
                  id={'toggleVisibilityButton-0'}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40px',
                    height: '40px',
                    backgroundColor: sliderData[0].visible ? primaryColor : '#ffffff',
                    color: '#fff',
                    border: "1px solid #1F555A",

                    transition: 'background-color 0.3s ease',
                  }}
                  onClick={() => handleToggleVisibility(0)}
                >
                  {sliderData[0].visible ? <MySVG1 /> : <MySVG8 />}
                </div>
              </OverlayTrigger>
            </div>

            {/* Model 1 */}
            <div className=" container">
              <label className="container">{sliderData[1].name}</label>
              <OverlayTrigger

                placement="left"
                overlay={<Tooltip >Toggle Prep/Anta visibility</Tooltip>}
              >
                <div
                  className="container rounded-3"
                  id={'toggleVisibilityButton-1'}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40px',
                    height: '40px',
                    backgroundColor: sliderData[1].visible ? primaryColor : '#ffffff',
                    color: '#fff',
                    border: "1px solid #1F555A",
                    transition: 'background-color 0.3s ease',
                  }}
                  onClick={() => handleToggleVisibility(1)}
                >
                  {sliderData[1].visible ? <MySVG9 /> : <MySVG7 />}
                </div>
              </OverlayTrigger>

            </div>

          </div>

          <hr style={{ width: "100%", border: "1px solid #000" }} />

          <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{ background: "rgba(255,255,255,1" }}>
            <div>Grid</div>
            <OverlayTrigger

              placement="left"
              overlay={<Tooltip >Show/Hide grid View
              </Tooltip>}
            >
              <button id='grid' className='rounded'
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  backgroundColor: primaryColor,
                  color: '#fff',
                  border: "1px solid #1F555A",
                  transition: 'background-color 0.3s ease',
                }} ><MySVG3 color='#808080' /></button></OverlayTrigger>
            <div>Wireframe</div>
            <OverlayTrigger

              placement="left"
              overlay={<Tooltip >Show/Hide wireframe view
              </Tooltip>}
            >
              <button id='wireframe' className='rounded'
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  backgroundColor: primaryColor,
                  color: '#fff',
                  border: "1px solid #1F555A",
                  transition: 'background-color 0.3s ease',
                }} >
                <MySVG2 />
              </button></OverlayTrigger>
          </div>


        </>
        )}




        <hr style={{ width: "100%", border: "1px solid #000" }} />
        <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{ background: "rgba(255,255,255,1" }}>
          <div>Toolbox</div>
          <button
            className='btn '
            style={{
              backgroundColor: primaryColor,
              textDecorationColor: "#ffffff",

              fontFamily: 'Manrope, sans-serif'
            }}
            onClick={() => { setTool(!tool) }}
          >
            <Toolbox />
          </button>
        </div>

      </div>

    </div>
  )


  return (
    <>
      <div className={` d-flex `} style={{ overflow: 'hidden', backgroundColor: "#ffffff", fontFamily: 'Manrope, sans-serif', fontWeight: "bold" }}>

        {loading && (
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '9999',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '20px',
            borderRadius: '5px',
            textAlign: 'center',
            backdropFilter: 'blur(10px)',
            width: "50vw"
          }}>
            {/* <div className="mb-3">
            <h2 className="card-title mb-4 text-center fw-semibold">Do you know?</h2>
            <p>
              <strong> {randomFact.fact}</strong>
            </p>
          </div> */}
            <ClipLoader size={50} color="#007bff" loading={loading} />
          </div>
        )}

        <div className="flex-1" style={{ position: 'relative' }}>
          <div id="canvas-container" ref={containerRef}>
            {error && (
              <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ position: 'absolute', top: '10px', left: '10px', zIndex: '1000', width: "72vw" }}>
                Some Error Occured!
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleAlertClose}></button>
              </div>
            )}
            <div id='defaultview' style={{ zIndex: 2000, position: "absolute", left: "2%", top: "2%", cursor: "pointer" }} onClick={setdefaultview}>
              <OverlayTrigger

                placement="bottom"
                overlay={<Tooltip >Reset to default view
                </Tooltip>}
              >
                <View /></OverlayTrigger>
              <div>Default View</div>

            </div>
            <div style={{ zIndex: 2000, position: "absolute", left: "30%", top: "10%" }}
            >
              {statusbar}
            </div>


            <div style={{ zIndex: 2000, position: "absolute", left: "2%", top: "50%", cursor: "pointer" }} >
              {brushingView}
              {marginView}
              {anatomyView}
              {smoothView}
              {calculatDistanceView}
            </div>


          </div>
        </div>
        {
          adjustment
        }

<div className='position-absolute ' style={{
          left: '5%',
          bottom: '3%'
        }}>


          {/* <button
            className='btn btn-primary fs-5'
            style={{
              backgroundColor: primaryColor,
              textDecorationColor: "#ffffff",
              margin: '10px',
              fontWeight: 'bolder',
              fontFamily: 'Manrope, sans-serif'
            }}
            onClick={()=>{updateCrownArrayBeforeExport()}}
          >
            Save Progress
          </button> */}


        </div>

        <div className='position-absolute ' style={{
          right: '10%',
          bottom: '3%'
        }}>


          <button
            className='btn btn-primary fs-5'
            style={{
              backgroundColor: primaryColor,
              textDecorationColor: "#ffffff",
              margin: '10px',
              fontWeight: 'bolder',
              fontFamily: 'Manrope, sans-serif'
            }}
            onClick={handlesave}
          >
            Export Crown
          </button>


        </div>

      </div>
    </>
  );
};

export default Crown;
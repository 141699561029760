import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LinearProgress,CircularProgress } from '@mui/material';
import {Container} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import image from '../assets/image.jpeg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useParams } from 'react-router-dom';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Dental AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

function New_user() {
  const { uid,token } = useParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [Email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [page, setPage] = useState(1);

    const handleTermsChange = (event) => {
        setTermsChecked(event.target.checked);
    };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate password and confirm password
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(`http://34.170.168.171:4000/new_user_confirm/${uid}/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "username": username,"password": password,"first_name":firstName,"last_name":lastName,"email":Email  }),
      });

      

      if (response.status === 201) {
        setLoading(false);
        navigate('/login');
      } else {
        setLoading(false);
       
        setError('Signup failed. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      setError('An unexpected error occurred. Please try again.');
    }
  };


  const firstPage = (
    <div style={{fontFamily: 'Manrope, sans-serif'}}>
        <Box
        
          sx={{
            margin: 8,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: "0 0 4px 4px rgba(144, 200, 224, 0.4)",
            // fontFamily: 'Manrope, sans-serif'
          }}
        >
           <Grid container direction="row"  justifyContent="center" spacing={2} sx={{margin:4}}>
            <Grid item xs={4} container justifyContent="center" >
                <img src={image} style={{ height:"50vh", width: "50vh",overflow: "hidden",objectFit:"cover" }} />
            </Grid>
            <Grid item xs={5}>
          <Avatar sx={{ m: 1, bgcolor: '#213F99' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Username"
                  
                  autoComplete="Username"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Password"
                  autoComplete="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Confirm Password"
                  autoComplete="Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
          
                
                
                
                
              <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" onChange={handleTermsChange} />}
                label="I agree to the Terms and Conditions."
            />

              </Grid>
            </Grid>
            <button
            className='btn btn-primary'
            disabled={!termsChecked}
            style={{
                backgroundColor: "#213F99",
                textDecorationColor: "#ffffff",
                width: '100%',
                fontFamily: 'Manrope, sans-serif'
            }}
            onClick={(e) => {
              handleSubmit(e);
          }}
          >
                            {loading ? <CircularProgress size={24} color="inherit" /> : "Submit Request"}
          </button>
            <Grid container sx={{mt:1}} justifyContent="flex-end">
              <Grid item>
              <Typography>Already have an account? 
        <Link to="/login" style={{ textDecoration: 'none',marginLeft:"4px" }}>
                Sign in
        </Link>
        </Typography>
              </Grid>
            </Grid>
          </Box>
          </Grid>
          </Grid>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box>
        
        </div>
  )


  return (
    <ThemeProvider theme={defaultTheme}>
      <div style={{display: page===1?"block":"none" }}>
      {firstPage}
      </div>
</ThemeProvider>

  );
}

export default New_user;

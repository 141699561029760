import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import bg from "../Images/logo.png"
import { useState } from 'react';
import { LinearProgress } from '@mui/material';
import { ReactComponent as MySVG1 } from '../assets/account_circle.svg';
import {ReactComponent as Bell} from '../assets/bell.svg';
import {ReactComponent as Logo} from '../assets/Logo.svg';

function Navbar() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("Anonymous"); 
  const location = useLocation();
  const { pathname } = location;

  // Check if token is present in sessionStorage
  const isUserLoggedIn = sessionStorage.getItem('token');
  
  useEffect(() => {
    getUserData();
  }, [pathname]);

  const handleLogout = async () => {
    try {
      setLoading(true);
      // Make API call to logout endpoint
      const response = await fetch('https://api.dentalai.ai/logout/', {
        method: 'POST', // Use POST method for logout
        headers: {
          Authorization: `Token ${isUserLoggedIn}`, // Include the token in the headers
        },
      });

      if (response.status === 200) {
        setLoading(false);
        // Clear the token from sessionStorage
        sessionStorage.removeItem('token');
        // Redirect to home page
        navigate('/login');
      } else {
        setLoading(false);
       
      }
    } catch (error) {
      setLoading(false);
      
    }
  };
  
  const getUserData = async () => {
    try {
      const authToken = sessionStorage.getItem('token');
      const response = await fetch('https://api.dentalai.ai/get_user/', {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });
      const data = await response.json();
      console.log(data);
      setUser(data.username);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleLogoClick = () => {
    if (isUserLoggedIn) {
      navigate('/fileview');
    }
    else {
      navigate('/');
    }
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg" style={{ height: "6vh", background: "rgba(255, 255, 255, 0.9)", display: "flex", justifyContent: "space-between", borderBottom: '0.5px  #00AEEF', boxShadow: '0 4px 4px rgba(144, 200, 224, 0.2)', fontFamily: 'Manrope, sans-serif' }}>
        <div className="container">
          {/* Logo on the left */}
          <Logo style={{ width: '10%', cursor: "pointer" }} alt="Navbar Image" onClick={handleLogoClick} />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          </div>

          <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
            <ul className="navbar-nav">
              {(pathname.endsWith('/prep') || pathname.endsWith('/crown')) && (
                <>
                  <li className="nav-item">
                    {/* Render Tutorial button only on '/prep' page */}
                    <button className="nav-link" onClick={() => alert('No New Notifs!')} style={{ color: '#000000', border: 'none', background: 'none', cursor: 'pointer' }}>
                      <Bell />
                    </button>
                  </li>
                  <li className="nav-item">
                    {/* Render Tutorial button only on '/prep' page */}
                    <button className="nav-link" onClick={() => alert('Tutorial is under Progress!')} style={{ color: '#000000', border: 'none', background: 'none', cursor: 'pointer',fontWeight:"bold" }}>
                      Tutorial
                    </button>
                  </li>
                </>
              )}
              <li className="nav-item">
                {isUserLoggedIn ? (
                  <div className='d-flex'>
                    {/* Dropdown menu */}
                    <div className="dropdown">
                      {/* Dropdown button */}
                      <button className="btn dropdown-toggle" type="button" id="navbarDropdownMenuRight" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: '#000000',fontWeight:"bold" }}>
                        <MySVG1 /> Hello {user ? user : "User"}!
                      </button>
                      {/* Dropdown menu items */}
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuRight">
                        <li><a className="dropdown-item" href="#" onClick={handleLogout}>Logout</a></li>
                        <li><Link to="/fileview" className="dropdown-item">Dashboard</Link></li>
                        <li><Link to="/feedback" className="dropdown-item">Feedback</Link></li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  null
                )}
              </li>
              {pathname.endsWith('/') && (
              <li>
              <Link to="/login" className="nav-link" style={{ color: '#000000' }}>
                    Get Started
                  </Link>
              </li>)}
            </ul>
          </div>
        
      </nav>
      {loading && (
        <LinearProgress sx={{ width: '100%', position: "fixed" }} />
      )}
    </>
  );
}

export default Navbar;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LinearProgress,CircularProgress } from '@mui/material';
import {Container} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import image from '../assets/image.jpeg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Dental AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

function Signup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [Email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [otp, setOtp] = useState('');
  const [page, setPage] = useState(1);
  const [orgname, setOrgname] = useState(null);
  const [orgtype, setOrgtype] = useState(null);
  const [labtype, setLabtype] = useState(null);
  const [practice, setPractice] = useState(null);
  const [numberofemployees, setNumberofemployees] = useState(null);
    const [crownvolume, setCrownvolume] = useState(null);
  const [skiploader, setSkiploader] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    // if (token) {
    //   navigate('/fileview');
    // }
  }, []);

    const handleTermsChange = (event) => {
        setTermsChecked(event.target.checked);
    };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate password and confirm password
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    try {
      setLoading(true);
      const response = await fetch('https://api.dentalai.ai/register/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "username": username,"password": password,"first_name":firstName,"last_name":lastName,"email":Email  }),
      });

      

      if (response.status === 201) {
        setLoading(false);
        // Signup successful, you can optionally handle this (e.g., show success message)
        

        
        // Use navigate to navigate to "/upload"
        setPage(2);
        // Redirect to login page
      } else {
        setLoading(false);
       
        setError('Signup failed. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try{
      setLoading(true);
    const response = await fetch('https://api.dentalai.ai/invite_only/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "email": Email ,"first_name":firstName,"last_name":lastName,"organization_name":orgname,"organization_type":orgtype,"lab_type":labtype,"practice_type":practice,"number_of_employees":numberofemployees,"crown_volume":crownvolume}),
      });

      

      if (response.status === 201) {
        setPage(3);
        setLoading(false);
        
      } else {
        setLoading(false);
       
        setError('Signup failed. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      setError('An unexpected error occurred. Please try again.');
    }
}

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const authToken = sessionStorage.getItem('token');
      setLoading(true);
      const response = await fetch('https://api.dentalai.ai/verify_otp/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "otp_code": otp ,"username": username}),
      });

      

      if (response.status === 200) {
        setLoading(false);
        const { token } = await response.json();

        // Store the token in session storage
        sessionStorage.setItem('token', token);
        navigate('/onboarding');
      } else {
        setLoading(false);
       
        setError('Signup failed. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      setError('An unexpected error occurred. Please try again.');
    }
  }

  const  handlesecondPage =  (e) => {
    setPage(2);
  }


  const firstPage = (
    <div style={{fontFamily: 'Manrope, sans-serif'}}>
        <Box
        
          sx={{
            margin: 8,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: "0 0 4px 4px rgba(144, 200, 224, 0.4)",
            // fontFamily: 'Manrope, sans-serif'
          }}
        >
           <Grid container direction="row"  justifyContent="center" spacing={2} sx={{margin:4}}>
            <Grid item xs={4} container justifyContent="center" >
                <img src={image} style={{ height:"50vh", width: "50vh",overflow: "hidden",objectFit:"cover" }} />
            </Grid>
            <Grid item xs={5}>
          <Avatar sx={{ m: 1, bgcolor: '#213F99' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
          
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        id="email"
                        label="Organization Name"
                        name="email"
                        autoComplete="email"
                        onChange={(e) => setOrgname(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                    {!orgtype && <InputLabel  shrink={false}>Organization Type</InputLabel>}
                        <Select
                    
                            labelId="roleLabel"
                            id="role"
                            onChange={(e) => {setOrgtype(e.target.value)}}
                            
                        >
                            <MenuItem value="Dental Lab">Dental Lab</MenuItem>
                            <MenuItem value="DSO">DSO</MenuItem>
                            <MenuItem value="Dentist / Dental Practice">Dentist / Dental Practice</MenuItem>
                            <MenuItem value="Investor">Investor</MenuItem>
                            <MenuItem value="Dental School / College">Dental School / College</MenuItem>
                            <MenuItem value="Manufacturer">Manufacturer</MenuItem>
                            <MenuItem value={null}>N/A</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth   sx={{ mt: 1 }}>
                       {!labtype && <InputLabel shrink={false} >Select Lab Type</InputLabel>}
                        <Select
                            
                            
                            
                            onChange={(e) => setLabtype(e.target.value)}
                           
                        >
                            <MenuItem value="Full Service">Full Service</MenuItem>
                            <MenuItem value="Crown & Bridge">Crown & Bridge</MenuItem>
                            <MenuItem value="Milling Center">Milling Center</MenuItem>
                            <MenuItem value="Implant">Implant</MenuItem>
                            <MenuItem value="Dentures">Dentures</MenuItem>
                            <MenuItem value={null}>N/A</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  fullWidth sx={{ mt: 1 }}>
                        {!practice && <InputLabel shrink={false}  id="roleLabel">Practice Type</InputLabel>}
                        <Select
                            labelId="roleLabel"
                            id="role"
                            
                            onChange={(e) => setPractice(e.target.value)}
                            
                        >
                            <MenuItem value="DSO">DSO</MenuItem>
                            <MenuItem value="Solo">Solo</MenuItem>
                            <MenuItem value="Private Practice">Private Practice</MenuItem>
                            <MenuItem value="Dentist Owned Group Practice">Dentist Owned Group Practice</MenuItem>
                            <MenuItem value="Corporate Owned Group Practice">Corporate Owned Group Practice</MenuItem>
                            <MenuItem value={null}>N/A</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl   fullWidth sx={{ mt: 1 }}>
                        {!numberofemployees &&<InputLabel shrink={false}  id="roleLabel">No. of Employees</InputLabel>}
                        <Select
                            labelId="roleLabel"
                            id="role"
                            
                            onChange={(e) => setNumberofemployees(e.target.value)}
                            
                        >
                            <MenuItem value={1}>1-5</MenuItem>
                            <MenuItem value={5}>5-10</MenuItem>
                            <MenuItem value={10}>11-20</MenuItem>
                            <MenuItem value={20}>21-30</MenuItem>
                            <MenuItem value={30}>31-40</MenuItem>
                            <MenuItem value={40}>40+</MenuItem>
                            <MenuItem value={null}>N/A</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl  fullWidth sx={{ mt: 1 }}>
                        {!crownvolume &&<InputLabel shrink={false}  id="roleLabel">Monthly Crown Volume</InputLabel>}
                        <Select
                            labelId="roleLabel"
                            id="role"
                            
                            onChange={(e) => {setCrownvolume(e.target.value)}}
                            
                        >
                            <MenuItem value={10}>10-50</MenuItem>
                            <MenuItem value={50}>51-100</MenuItem>
                            <MenuItem value={100}>101-200</MenuItem>
                            <MenuItem value={200}>201-500</MenuItem>
                            <MenuItem value={500}>500+</MenuItem>
                            <MenuItem value={null}>N/A</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
              <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" onChange={handleTermsChange} />}
                label="I agree to the Terms and Conditions."
            />

              </Grid>
            </Grid>
            <button
            className='btn btn-primary'
            disabled={!termsChecked}
            style={{
                backgroundColor: "#213F99",
                textDecorationColor: "#ffffff",
                width: '100%',
                fontFamily: 'Manrope, sans-serif'
            }}
            onClick={(e) => {
              handleSignup(e)
          }}
          >
                            {loading ? <CircularProgress size={24} color="inherit" /> : "Submit Request"}
          </button>
            <Grid container sx={{mt:1}} justifyContent="flex-end">
              <Grid item>
              <Typography>Already have an account? 
        <Link to="/login" style={{ textDecoration: 'none',marginLeft:"4px" }}>
                Sign in
        </Link>
        </Typography>
              </Grid>
            </Grid>
          </Box>
          </Grid>
          </Grid>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box>
        
        </div>
  )

  const ThirdPage = (
<div style={{ fontFamily: 'Arial, sans-serif' }}>
    <Box
      sx={{
        margin: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0 0 4px 4px rgba(144, 200, 224, 0.4)',
        padding: 4,
        borderRadius: 2,
      }}
    >
      <Avatar sx={{ bgcolor: '#006400' }}>
        <CheckCircleOutlineIcon style={{ fontSize: 40 }} />
      </Avatar>
      <Typography variant="h5" align="center" gutterBottom>
        Your Details Have Been Received
      </Typography>
      <Typography align="center" sx={{ marginTop: 2 }}>
        Thank you for showing interest in our latest AI solution. 
      </Typography>
      <Typography align="center" sx={{ marginBottom: 4 }}>
      Your information has been received. Our team will review it and contact you soon.
      </Typography>
      <Link to="/login" style={{ textDecoration: 'none', color: '#006400', fontWeight: 'bold' }}>
        Back to Home
      </Link>
    </Box>
  </div>
  )

  const verification = (
    <div style={{fontFamily: 'Manrope, sans-serif'}}>
        <Box
        
          sx={{
            margin: 8,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: "0 0 4px 4px rgba(144, 200, 224, 0.4)",
            // fontFamily: 'Manrope, sans-serif'
          }}
        >
           <Grid container direction="row"  justifyContent="center" spacing={2} sx={{margin:4}}>
            <Grid item xs={4} container justifyContent="center" >
                <img src={image} style={{ height:"50vh", width: "50vh",overflow: "hidden",objectFit:"cover" }} />
            </Grid>
            <Grid item xs={5}>
          <Avatar sx={{ m: 1, bgcolor: '#213F99' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Verify OTP
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm OTP"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            onChange={(e) => setOtp(e.target.value)}
          />
           
              </Grid>
              
              
            </Grid>
            <button
            className='btn btn-primary'
            style={{
                backgroundColor: "#213F99",
                textDecorationColor: "#ffffff",
                width: '100%',
                fontFamily: 'Manrope, sans-serif'
            }}
            onClick={handleVerify}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Sign Up"}
          </button>
            <Grid container sx={{mt:1}} justifyContent="flex-end">
              <Grid item>
              <Typography>Already have an account? 
        <Link to="/login" style={{ textDecoration: 'none',marginLeft:"4px" }}>
                Sign in
        </Link>
        </Typography>
              </Grid>
            </Grid>
          </Box>
          </Grid>
          </Grid>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box>
        
        </div>
  )

  return (
    <ThemeProvider theme={defaultTheme}>
      <div style={{display: page===1?"block":"none" }}>
      {firstPage}
      </div>
      <div style={{display: page===3?"block":"none" }}>
      {ThirdPage}
      </div>
      
</ThemeProvider>

  );
}

export default Signup;

import React, { useRef, useEffect , useState} from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import { useLocation } from 'react-router-dom';
import { toCreasedNormals } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter';
//   if (margin) {
//     const pcdLoader = new PCDLoader();
//     const pcdBlob = new Blob([margin], { type: 'application/octet-stream' });
//     const pcdUrl = URL.createObjectURL(pcdBlob);

//     pcdLoader.load(pcdUrl, (points) => {
//       const positions = points.geometry.attributes.position.array;
//       const pointArray = [];

//       for (let i = 0; i < positions.length; i += 3) {
//         pointArray.push(new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]));
//       }
//       console.log("initial: ",pointArray);

//       // Sample control points evenly from the original PCD points
//       const numControlPoints = 250
//       const controlPoints = [];
//       const stepSize = Math.floor(pointArray.length / numControlPoints);
//       for (let i = 0; i < numControlPoints; i++) {
//         controlPoints.push(pointArray[i * stepSize])
//       }
//       curveRef.current = new THREE.CatmullRomCurve3(controlPoints,true);
//       if(curveRef.current){
//       updateSpline(); 
//       }
//       const pointsGeometry = new THREE.SphereGeometry(0.1, 32, 32);
//       const controlPointMaterial = new THREE.MeshStandardMaterial({
//         color: 0x000000,
//         metalness: 0.3,
//         roughness: 0.4,
//         emissive: 0x000000
//       });

//       // Add spheres for control points
//       controlPoints.forEach((point, index) => {
//         if(index%25===0){
//         const sphere = new THREE.Mesh(pointsGeometry, controlPointMaterial);
//         sphere.position.copy(point);
//         sphere.userData.index = index;
//         scene.add(sphere);
//         spheresRef.current.push(sphere)
//         }
//       });
      

//       // Function to update the spline
//       function updateSpline() {
//         if (splineObjectRef.current) {
//           scene.remove(splineObjectRef.current);
//           splineObjectRef.current.geometry.dispose();
//         }
      
//         const divisions = 250;
//         const curvePoints = [];
      
//         // Get reference points for normal calculation
//         const p0 = curveRef.current.getPoint(0);
//         const p1 = curveRef.current.getPoint(0.5);
//         const p2 = curveRef.current.getPoint(0.25);
//         const p3 = curveRef.current.getPoint(0.75);
      
//         const v1 = new THREE.Vector3().subVectors(p1, p0);
//         const v2 = new THREE.Vector3().subVectors(p3, p2);
//         const up = new THREE.Vector3().crossVectors(v1, v2).normalize();
//         up.negate();
      
//         const center1 = new THREE.Vector3().addVectors(p0, p1).add(p2).add(p3).multiplyScalar(0.25)

      
//         // Sample points along the curve
//         for (let i = 0; i <= divisions; i++) {
//           const t = i / divisions;
//           const point = new THREE.Vector3();
//           curveRef.current.getPoint(t, point);
//           curvePoints.push(point);
//         }
      
//         // Project points onto the mesh surface
//         const projectedPoints = [];
//         const selectedIndex = selectedSphereRef.current?.userData.index;
//         const totalControlPoints = curveRef.current.points.length;
      
//         curvePoints.forEach((point, idx) => {
//           // Calculate which segment of the curve this point belongs to
//           const t = idx / divisions;
//           const nearestControlPoint = Math.floor(t * (totalControlPoints - 1));
//           const center = new THREE.Vector3();
          
//           if (selectedIndex !== undefined) {
//             // Define the range for projection (between previous and next control points)
//             const leftNeighbor = Math.max(0, selectedIndex - 1);
//             const rightNeighbor = Math.min(totalControlPoints - 1, selectedIndex + 1);
            
//             // Calculate t values for the boundaries of our projection region
//             const leftBoundary = leftNeighbor / (totalControlPoints - 1);
//             const rightBoundary = rightNeighbor / (totalControlPoints - 1);
            
//             // Only project points that fall between the neighboring control points
//             if (t >= leftBoundary && t <= rightBoundary) {
//               center.copy(point).sub(up.clone().multiplyScalar(0.2));
//               const ray = new THREE.Raycaster(center, up.clone(), 0, 12);
//               const intersects = ray.intersectObject(meshRef.current);
              
//               if (intersects.length > 0) {
//                 const projectedPoint = intersects[0].point.clone();
//                 projectedPoints.push(projectedPoint);
//               } else {
//                 // If no intersection, keep the original point
//                 projectedPoints.push(point.clone());
//               }
//             } else {
//               // Outside the projection region, keep the original point
//               projectedPoints.push(point.clone());
//             }
//           } 
          
//           else {
//             // If no point is selected, use original behavior (project all points)
//             center.copy(point).sub(up.clone().multiplyScalar(0.2));
//             const ray = new THREE.Raycaster(center, up.clone(), 0, 12);

//             const intersects = ray.intersectObject(meshRef.current)
//             const spheregeo = new THREE.SphereGeometry(0.11, 32, 32);
//             const spheremat = new THREE.MeshBasicMaterial({color: 0x0fff0});
//             const sphere = new THREE.Mesh(spheregeo, spheremat);
//             sphere.position.copy(center)
//             // scene.add(sphere);
//             if (intersects.length > 0) {
//               const projectedPoint = intersects[0].point.clone();
//               const spheregeo = new THREE.SphereGeometry(0.11, 32, 32);
//               const spheremat = new THREE.MeshBasicMaterial({color: 0x0ff0f});
//               const sphere = new THREE.Mesh(spheregeo, spheremat);
//               sphere.position.copy(projectedPoint);
//               // scene.add(sphere)
//               projectedPoints.push(projectedPoint);
//             } else {
//               // const projectedPoint = point.clone();
//               // const spheregeo = new THREE.SphereGeometry(0.11, 32, 32);
//               // const spheremat = new THREE.MeshBasicMaterial({color: 0x0ff0f});
//               // const sphere = new THREE.Mesh(spheregeo, spheremat);
//               // sphere.position.copy(projectedPoint);
//               // scene.add(sphere)
//               projectedPoints.push(point.clone())
//             }
//           }
//         });
//         console.log("projected: ",projectedPoints);
        
      
//         // Create a new curve from the projected points
//         const projectedCurve = new THREE.CatmullRomCurve3(projectedPoints,true);
      
//         // Create new tube geometry with the projected curve
//         const tubeGeometry = new THREE.TubeGeometry(
//           projectedCurve, 
//           projectedPoints.length,
//           0.05, 
//           8, 
//           false
//         );
        
//         splineObjectRef.current = new THREE.Mesh(tubeGeometry, splineMaterial);
//         splineObjectRef.renderOrder = -1;
//         scene.add(splineObjectRef.current);
//       }
      
      
      

//       dragControls.addEventListener('dragstart', () => {
//         controls.enabled = false;
//       });
// // Add these refs at the top of your component

// // Inside your useEffect, add this after creating dragControls:

// const onMouseMove = (event) => {
//   if (isDraggingRef.current && selectedSphereRef.current && meshRef.current) {
//     const rect = renderer.domElement.getBoundingClientRect();
//     mouseRef.current.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
//     mouseRef.current.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
//     raycaster.setFromCamera(mouseRef.current, camera);
//     const intersects = raycaster.intersectObject(meshRef.current);

//     if (intersects.length > 0) {
//       const closestPoint = intersects[0].point;
//       const sphere = selectedSphereRef.current;
//       sphere.position.copy(closestPoint)

//       // Update the corresponding control point in the curve
//       if (curveRef.current) {
//         curveRef.current.points[sphere.userData.index].copy(closestPoint);
//         updateSpline();
//       }
//     }
//   }
// };

// dragControls.addEventListener('dragstart', (event) => {
//   controls.enabled = false;
//   isDraggingRef.current = true;
//   selectedSphereRef.current = event.object;
// });

// dragControls.addEventListener('dragend', () => {
//   controls.enabled = true;
//   isDraggingRef.current = false;
//   selectedSphereRef.current = null;
// });

// // Add and remove the mousemove event listener
// renderer.domElement.addEventListener('mousemove', onMouseMove);

//       dragControls.addEventListener('dragend', () => {
//         controls.enabled = true;
//       });

//       dragControls.addEventListener('hoveron', (event) => {
//         event.object.material.emissive?.setHex(0x333333);
//       });

//       dragControls.addEventListener('hoveroff', (event) => {
//         event.object.material.emissive?.setHex(0x000000)
//       });
//     })
// }

const MeshManipulation = () => {
  const location = useLocation();
  const { prep, margin, wire } = location.state || {}

  const mountRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  const cameraRef = useRef(new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000));
  const rendererRef = useRef(null);
  const meshRef = useRef(null);
  const curveRef = useRef(null);
  const raycasterRef = useRef(new THREE.Raycaster());
  const mouseRef = useRef(new THREE.Vector2());
  const [axis, setAxis] = useState(null);
  const [startpoint, setStartpoint] = useState(null);
  const selectedPoints = useRef([]); // To store the points for the line
  let isDragging = useRef(false);

  useEffect(() => {
    const scene = sceneRef.current;
    const camera = cameraRef.current;
    const mount = mountRef.current;
    const raycaster = raycasterRef.current;

    // Initialize renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    renderer.autoClear = false;
    mount.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    // Set camera position
    camera.position.set(-42.573, -53.444, 9.906);
    camera.up.set(0, 0, 1);

    // Lighting
    scene.add(new THREE.AmbientLight(0xffffff, 0.4));
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.9);
    directionalLight.position.set(0, 0, 1);
    scene.add(directionalLight);

    // Orbit Controls
    const controls = new TrackballControls(camera, renderer.domElement);
    controls.enableRotate = true;
    controls.noPan = false;
    controls.enablePan = true;
    controls.enableZoom = true;
    controls.enableDamping = true;
    controls.dampingFactor = 0.8;
    controls.rotateSpeed = 2.5;
    controls.zoomSpeed = 1;
    controls.panSpeed = 0.5;
    // controls.screenSpacePanning = true;
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI;
    controls.minAzimuthAngle = -Infinity;
    controls.maxAzimuthAngle = Infinity;
    controls.maxDistance = 500; // Maximum zoom distance
    controls.minDistance = 5;   // Minimum zoom distance
    controls.enableKeys = true;
    controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.ROTATE,
    };

    // Shaders
    const vertexShader = `
      varying vec3 vNormal;
      varying vec3 vViewPosition;
      varying vec3 vLightDirection;
      uniform vec3 lightPosition;

      void main() {
        vNormal = normalMatrix * normal;
        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
        vViewPosition = -mvPosition.xyz;
        vLightDirection = normalize(lightPosition - mvPosition.xyz);
        gl_Position = projectionMatrix * mvPosition;
      }
    `;

    const fragmentShader = `
    uniform vec3 uColor;
    varying vec3 vNormal;
    varying vec3 vViewPosition;
    varying vec3 vLightDirection;

    void main() {
        // Flip the normal if it's a back face
        vec3 normal = normalize(gl_FrontFacing ? vNormal : -vNormal);
        vec3 viewDir = normalize(vViewPosition);
        
        // Diffuse lighting
        float diff = max(dot(normal, vLightDirection), 0.0);
        vec3 diffuse = diff * uColor;
        
        // Specular reflection
        vec3 reflectDir = reflect(-vLightDirection, normal);
        float spec = pow(max(dot(viewDir, reflectDir), 0.0), 32.0);
        vec3 specular = vec3(0.3) * spec;
        
        // Fresnel effect
        float fresnel = pow(1.0 - max(dot(normal, viewDir), 0.0), 3.0);
        vec3 fresnelColor = vec3(0.1, 0.1, 0.1) * fresnel;
        
        // Final color
        vec3 finalColor = diffuse + specular + fresnelColor;
        gl_FragColor = vec4(finalColor, 1.0);
    }
`;


    // Materials
    const meshMaterial = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      side: THREE.DoubleSide,
      wireframe: wire,
      uniforms: {
        uColor: { value: new THREE.Color(0xf2f2e8) },
        lightPosition: { value: new THREE.Vector3(0, 0, 10) }
      }
    });

    const splineMaterial = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uColor: { value: new THREE.Color(0x00000) },
        lightPosition: { value: new THREE.Vector3(0, 0, 10) }
      },
      side: THREE.DoubleSide
    })

    let newSplinecurve

    // Transform Controls Setup
    const updateTubeGeometry = () => {
      if (selectedPoints.current.length >= 2) {
        newSplinecurve = new THREE.CatmullRomCurve3(selectedPoints.current);
        const tubeGeometry = new THREE.TubeGeometry(newSplinecurve, selectedPoints.current.length * 5, 0.05, 8, false);
        const splineMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });

        // Remove the previous spline
        const oldSpline = scene.getObjectByName('newSpline');
        if (oldSpline) {
          scene.remove(oldSpline);
        }
        const tubeMesh = new THREE.Mesh(tubeGeometry, splineMaterial);
        tubeMesh.name = 'newSpline';
        scene.add(tubeMesh);
      }
    };

    // Mouse down event to start tracking points
    const onMouseDown = (event) => {
      event.preventDefault();
      isDragging.current = true;
      selectedPoints.current = []; // Reset points for a new spline
    };

    // Mouse move event to add points while dragging
    const onMouseMove = (event) => {
      if (!isDragging.current) return;

      if(event.ctrlKey){
        controls.enabled = false;
      // Update mouse vector with normalized device coordinates
      const rect = renderer.domElement.getBoundingClientRect();
      mouseRef.current.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouseRef.current.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      // Cast ray and check for intersections with mesh
      raycaster.setFromCamera(mouseRef.current, camera);
      const intersects = raycaster.intersectObject(meshRef.current);

      if (intersects.length > 0) {
        const point = intersects[0].point.clone();
        selectedPoints.current.push(point);
        updateTubeGeometry();
      }
    }
    };

    const update = (maincurve, newcurve) => {
      maincurve = maincurve.getPoints(250);
      if(!newcurve) return;
      const start = newcurve.getPoint(0);
      const end = newcurve.getPoint(1);
      let startind = 0, endind = 0;
      let minstartDist = 1e9, minendDist = 1e9;
      
      maincurve.forEach((point, index) => {
        const startDist = point.distanceTo(start);
        const endDist = point.distanceTo(end);
        if (startDist < minstartDist) {
          minstartDist = startDist;
          startind = index;
        }
        if (endDist < minendDist) {
          minendDist = endDist;
          endind = index;
        }
      });
    
      // Create new curve by replacing points between start and end indices
      const newmaincurve = [
        ...maincurve.slice(0, startind + 1),
        ...newcurve.getPoints(100),
        ...maincurve.slice(endind)
      ];
    
      return newmaincurve;
    };

    // Mouse up event to stop tracking points
    const onMouseUp = () => {
      isDragging.current = false;
      controls.enabled = true;
    
      const newmaincurve = update(curveRef.current, newSplinecurve);
      if(!newmaincurve) return;
      curveRef.current = new THREE.CatmullRomCurve3(newmaincurve, true);
      const orignalSpline = scene.getObjectByName('orignalSpline');
      if (orignalSpline) {
        scene.remove(orignalSpline);
      }
      const newSpline = scene.getObjectByName('newSpline');
      if (newSpline) {
        scene.remove(newSpline);
      }
      const tube = new THREE.TubeGeometry(curveRef.current, 100, 0.05, 8, false);
      const newCombinedSpline = new THREE.Mesh(tube, splineMaterial);
      newCombinedSpline.name = 'orignalSpline';
      scene.add(newCombinedSpline);
    };

    // Add event listeners for mouse events
    mount.addEventListener('mousedown', onMouseDown);
    mount.addEventListener('mousemove', onMouseMove);
    mount.addEventListener('mouseup', onMouseUp);
  // Load PCD File

  if (margin) {
    const pcdLoader = new PCDLoader();
    const pcdBlob = new Blob([margin], { type: 'application/octet-stream' });
    const pcdUrl = URL.createObjectURL(pcdBlob);
  
    pcdLoader.load(pcdUrl, (points) => {
      const positions = points.geometry.attributes.position.array;
      const pointArray = [];
  
      for (let i = 0; i < positions.length; i += 3) {
        pointArray.push(new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]));
      }
  
      // Sample control points evenly from the original PCD points
      const numControlPoints = 250;
      const controlPoints = [];
      const stepSize = Math.floor(pointArray.length / numControlPoints);
      for (let i = 0; i < numControlPoints; i++) {
        controlPoints.push(pointArray[i * stepSize]);
      }
  
      curveRef.current = new THREE.CatmullRomCurve3(controlPoints, true);
      const tube = new THREE.TubeGeometry(curveRef.current, 100, 0.05, 8, false);
      const orignalMesh = new THREE.Mesh(tube, splineMaterial);
      orignalMesh.name = 'orignalSpline';
      scene.add(orignalMesh);
    });
  }


    // Load STL File
    if (prep) {
      const stlLoader = new STLLoader();
      stlLoader.load(URL.createObjectURL(prep), (geometry) => {
        geometry = toCreasedNormals(geometry, (90 / 180) * Math.PI);
        const mesh = new THREE.Mesh(geometry, meshMaterial);
        scene.add(mesh);
        meshRef.current = mesh
      });
    }



    // Animation Loop
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    // Cleanup
    return () => {
      controls.dispose();
      mount.removeChild(renderer.domElement);
      mount.removeEventListener('mousedown', onMouseDown);
      mount.removeEventListener('mousemove', onMouseMove);
      mount.removeEventListener('mouseup', onMouseUp);

      if (prep) URL.revokeObjectURL(prep);
      if (margin) URL.revokeObjectURL(margin);
    };
  }, []);

  useEffect(() => {
    const scene = sceneRef.current;
    if(axis){
      const arrowLength = 15;
      const arrowColor = 0xff0000; 

      // Create the ArrowHelpe
      const arrowHelper = new THREE.ArrowHelper(
        axis.clone().normalize(), 
        startpoint,               
        arrowLength,
        arrowColor                
      );

      // Add ArrowHelper to the scene
      scene.add(arrowHelper);
      
    }

  },[axis,startpoint])


  const getNewMargin = () => {
    const numPoints = 1500;
    const points = [];
    
    // Sample points along the curve including the closing segment
    for (let i = 0; i < numPoints; i++) {  // Changed from <= to < to avoid duplicate point
      const t = i / numPoints;
      const point = new THREE.Vector3();
      curveRef.current.getPoint(t, point);
      points.push(point);
    }

    // Create PCD header
    let header = `# .PCD v0.7 - Point Cloud Data\n`;
    header += `VERSION 0.7\n`;
    header += `FIELDS x y z\n`;
    header += `SIZE 4 4 4\n`;
    header += `TYPE F F F\n`;
    header += `COUNT 1 1 1\n`;
    header += `WIDTH ${points.length}\n`;
    header += `HEIGHT 1\n`;
    header += `VIEWPOINT 0 0 0 1 0 0 0\n`;
    header += `POINTS ${points.length}\n`;
    header += `DATA ascii\n`;

    // Create points data
    let pointsData = '';
    points.forEach(point => {
      const x = point.x.toFixed(6);
      const y = point.y.toFixed(6);
      const z = point.z.toFixed(6);
      pointsData += `${x} ${y} ${z}\n`;
    });

    // Combine header and points data
    const pcdData = header + pointsData;
    return pcdData;
};

const calculateAxis = async () => {
    try {
        const pcdData = getNewMargin();
        
        // Create a Blob from the PCD data
        const pcdBlob = new Blob([pcdData], { type: 'text/plain' });
        
        // Append the Blob as a file in FormData
        const formData = new FormData();
        formData.append('margin', pcdBlob, 'margin.pcd')
        formData.append('prep', prep, 'prep.stl');

        const response = await fetch('http://34.71.126.92:4000/insertion_direction/', {
            method: 'POST',
            body: formData,
        });

        if (response.status === 200) {
            const responseData = await response.json();
            console.log(responseData);
            const points = Array.isArray(responseData) ? responseData : responseData.point;
            const start = points[1];
            const dir = points[0]
            const startPoint = new THREE.Vector3(start[0], start[1], start[2]);
            const direction = new THREE.Vector3(dir[0], dir[1], dir[2]);
            console.log("start Point",startPoint);
            console.log("direction",direction);
            setAxis(direction);
            setStartpoint(startPoint)
        } 
    } catch (error) {
        console.error(error);
    }
};


  const exportToPCD = () => {
    if (curveRef.current) {
      const numPoints = 5100;
      const points = [];
      
      // Sample points along the curve including the closing segment
      for (let i = 0; i < numPoints; i++) {  // Changed from <= to < to avoid duplicate point
        const t = i / numPoints;
        const point = new THREE.Vector3();
        curveRef.current.getPoint(t, point);
        points.push(point)
      }


      // Create PCD header
      let header = `# .PCD v0.7 - Point Cloud Data\n`;
      header += `VERSION 0.7\n`;
      header += `FIELDS x y z\n`;
      header += `SIZE 4 4 4\n`;
      header += `TYPE F F F\n`;
      header += `COUNT 1 1 1\n`;
      header += `WIDTH ${points.length}\n`;
      header += `HEIGHT 1\n`;
      header += `VIEWPOINT 0 0 0 1 0 0 0\n`;
      header += `POINTS ${points.length}\n`;
      header += `DATA ascii\n`;

      // Create points data
      let pointsData = '';
      points.forEach(point => {
        // Round to 6 decimal places to avoid floating point precision issues
        const x = point.x.toFixed(6);
        const y = point.y.toFixed(6);
        const z = point.z.toFixed(6);
        pointsData += `${x} ${y} ${z}\n`;
      });

      // Combine header and points data
      const pcdData = header + pointsData;

      // Create and download the file
      const blob = new Blob([pcdData], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'curve_points.pcd';
      link.click();
      URL.revokeObjectURL(link.href)
    }
  };


  return<div> <div ref={mountRef} style={{ width: '100%', height: '100vh' }} /> 
  <button 
        onClick={exportToPCD}
        style={{
          position: 'absolute',
          bottom: '5%',
          right: '5%',
          padding: '10px 20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '16px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          transition: 'background-color 0.3s ease'
        }}
        onMouseOver={(e) => e.target.style.backgroundColor = '#45a049'}
        onMouseOut={(e) => e.target.style.backgroundColor = '#4CAF50'}
      >
        Export STL
      </button>
      
      <button 
        onClick={calculateAxis}
        style={{
          position: 'absolute',
          bottom: '5%',
          right: '20%',
          padding: '10px 20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '16px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          transition: 'background-color 0.3s ease'
        }}
        onMouseOver={(e) => e.target.style.backgroundColor = '#45a049'}
        onMouseOut={(e) => e.target.style.backgroundColor = '#4CAF50'}
      >
        Recalculate Axis
      </button></div>
};

export default MeshManipulation;
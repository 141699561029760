import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { ClipLoader } from 'react-spinners';
import { debounce } from 'lodash';
import dentalFacts from './Json/dentalFacts.json';
import { ReactComponent as MySVG } from '../assets/Vector 14.svg';
import { ReactComponent as MySVG1 } from '../assets/Upper jaw.svg';
import { ReactComponent as MySVG2 } from '../assets/Tile.svg';
import { ReactComponent as MySVG3 } from '../assets/Grid.svg';
import { ReactComponent as MySVG4 } from '../assets/Model.svg';
import { ReactComponent as MySVG5 } from '../assets/delete.svg';
import { ReactComponent as MySVG6 } from '../assets/Add.svg';
import{ ReactComponent as MySVG7 } from '../assets/Lower jaw inactive.svg';
import { ReactComponent as MySVG8 } from '../assets/Upper jaw inactive.svg';
import { ReactComponent as MySVG9 } from '../assets/Lower jaw.svg';
import {ReactComponent as MySVG10} from '../assets/Check.svg';
import {ReactComponent as Delete} from '../assets/Close Icon.svg';
import {ReactComponent as View} from '../assets/Group.svg';
import {ReactComponent as Reset} from '../assets/Reset.svg';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {ReactComponent as PrepIcon} from '../assets/Prep.svg';
import {ReactComponent as CrownIcon} from '../assets/Magic.svg';
import {ReactComponent as Export} from '../assets/Export.svg';
import JSZip from 'jszip'; 
import ThreeJSManager from "./Crown Component/ThreeD"
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader.js';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import * as P from "./Prep/Prep_Utils"



const Prepmodule = () => {
  const location = useLocation();
  const { file1, file2, margin ,points,selectedOption,prepView,antaView,position } = location.state || {};
  const navigate = useNavigate();
  const [initialCameraPosition, setInitialCameraPosition] = useState(position ? position : new THREE.Vector3(-42.57305524045056, -53.444090713186604, 9.906063101665028));
  const [isRaycasterActive, setIsRaycasterActive] = useState(false);
  const [point, setpoint] = useState(points ? points : [10,10,10])
  const [prepPoints, setPrepPoints] = useState([point]);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const raycasterSphereRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [prepview, setPrepview] = useState(false);
  const [Adjustmentpanel, setAdjustmentPanel] = useState(true);
  const primarycolor = "#213F99";
  const [highlightSpheres, setHighlightSpheres] = useState([]);
  const [sliderData, setSliderData] = useState([
    
    { name: 'Prep', opacity: 1, visible: prepView },
    { name: 'Anta', opacity: 1, visible: antaView },
  ]);
  const containerRef = useRef(null);
  const [threeJSManager, setThreeJSManager] = useState(null);

  const [renderer, setRenderer] = useState(null);
  const [camera, setCamera] = useState(null);
  const [controls, setControls] = useState(null);
  const [scene, setScene] = useState(null);

  const curveRef = useRef(null);
  const raycaster = new THREE.Raycaster();
  const mouseRef = useRef(new THREE.Vector2());
  let isDragging = useRef(false);
  const selectedPoints = useRef([]); // To store the points for the line
  const [meshRef, setMeshRef] = useState(null);
  
  useEffect(() => {
   
    if (containerRef.current ) {
      const manager = new ThreeJSManager(
        containerRef.current.id,
        initialCameraPosition, null, sliderData, false,null, file1, file2, null, prepView, antaView, scene, camera, controls
      );
      manager.initRenderer();
      manager.initScene();
      manager.initCamera();
      manager.initControls();
      manager.animate();
      manager.loadAllSTLs();
      
      setScene(manager.scene);
      setRenderer(manager.renderer);
      setCamera(manager.camera);
      setControls(manager.controls);
      setThreeJSManager(manager);

      return () => {
        manager.cleanup();
      };
    }
  }, []);
  useEffect(() => {
    const axisButton = document.getElementById('grid');
    const wireframeButton = document.getElementById('wireframe');
    const viewButton = document.getElementById('defaultview');
    const opacitySliders = document.querySelectorAll('[id^="opacitySlider-"]');
    // const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
  
  

    if(threeJSManager)
      {const handleMouseDown = (event) => {
        if(event.button === 2){
          return;
        }
      const rect = renderer.domElement.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const point = intersects[0].point;
       
        setSelectedCoordinates(point);
        if (raycasterSphereRef.current) {
          scene.remove(raycasterSphereRef.current);
        }

        // Create a new raycaster sphere
        const raycasterMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
        const raycasterSphereGeometry = new THREE.SphereGeometry(0.16); // Adjust the size as needed
        const newRaycasterSphere = new THREE.Mesh(raycasterSphereGeometry, raycasterMaterial);
        newRaycasterSphere.position.copy(point);
        raycasterSphereRef.current = newRaycasterSphere;

        // Add the new raycaster sphere to the scene
        scene.add(newRaycasterSphere);
      }}
      if(isRaycasterActive){
    renderer.domElement.addEventListener('mousedown', handleMouseDown);}
  }
  if (threeJSManager) {

    // Remove existing highlight spheres
    highlightSpheres.forEach(sphere => scene.remove(sphere));

    const highlightMaterial = new THREE.MeshPhysicalMaterial({ 
    color: 0x4caf50,
    opacity: 0.5,
    transparent: true,
    roughness: 0.5,
    clearcoat: 1.0,
    clearcoatRoughness: 0.1,
    reflectivity: 1.0,
    transmission: 0.0,
  });
    const newHighlightSpheres = prepPoints.map((point) => {
      if(point[0]!==0 && point[1]!==0 && point[2]!==0) {
        const highlightGeometry = new THREE.SphereGeometry(4.5, 32, 32);
        const highlightMesh = new THREE.Mesh(highlightGeometry, highlightMaterial);
        highlightMesh.material.depthWrite = false;
        highlightMesh.renderOrder = 1;
        highlightMesh.position.set(point[0], point[1], point[2]);
        // scene.add(highlightMesh);
        return highlightMesh;
      }
      return null;
    }).filter(Boolean); // Remove null entries

    setHighlightSpheres(newHighlightSpheres);
  }
  
    const toggleAxes = () => {
      if (threeJSManager) {
        threeJSManager.toggleAxes();
      }
    };
  
    const toggleWireframe = () => {
      if (threeJSManager) {
        threeJSManager.toggleWireframe();
      }
    };
  
    const defaultView = () => {
      if (threeJSManager) {
        threeJSManager.defaultView();
      }
    };
  
    const handleOpacityChange = (index) => {
      if (threeJSManager) {
        threeJSManager.handleOpacityChange(index);
      }
    };

  
    if (axisButton) {
      axisButton.addEventListener('click', toggleAxes);
    }
  
    if (wireframeButton) {
      wireframeButton.addEventListener('click', toggleWireframe);
    }
  
    if (viewButton) {
      viewButton.addEventListener('click', defaultView);
    }
  
    opacitySliders.forEach((slider, index) => {
      slider.addEventListener('input', () => {
        handleOpacityChange(index);
      });
    });
  
    return () => {
  
      if (axisButton) {
        axisButton.removeEventListener('click', toggleAxes);
      }
  
      if (wireframeButton) {
        wireframeButton.removeEventListener('click', toggleWireframe);
      }
  
      if (viewButton) {
        viewButton.removeEventListener('click', defaultView);
      }
  
      opacitySliders.forEach((slider, index) => {
        slider.removeEventListener('input', () => {
          handleOpacityChange(index);
        });
      });
    };
  }, [threeJSManager,isRaycasterActive,prepPoints]);

  // useEffect(() => {
  //   if (threeJSManager && scene) {
  //         const checkMeshLoaded = () => {
  //           const prepMesh = scene.getObjectByName("prep");
            
  //           if (prepMesh) {
  //             console.log('Mesh loaded:', prepMesh);
  //             setMeshRef(prepMesh);
              
  //           } else {
  //             // Check again after a short delay if the mesh hasn't loaded yet.
  //             setTimeout(checkMeshLoaded, 100);  // Adjust the interval as needed
  //           }
  //         };
          
  //     checkMeshLoaded();  // Start checking for the mesh.
      
  //     const dragControls = new DragControls(spheresRef.current, camera, renderer.domElement);
  //     if (margin && meshRef) {
  //       console.log('Margin loaded:', margin);
  //       const splineMaterial = new THREE.MeshStandardMaterial({
  //         color: 0x000000,
  //         metalness: 0.3,
  //         roughness: 0.4,
  //         emissive: 0x000000
  //       });
  //       const pcdLoader = new PCDLoader();
  //       const pcdBlob = new Blob([margin], { type: 'application/octet-stream' });
  //       const pcdUrl = URL.createObjectURL(pcdBlob)
  //       pcdLoader.load(pcdUrl, (points) => {
  //         const positions = points.geometry.attributes.position.array;
  //         const pointArray = [];
    
  //         for (let i = 0; i < positions.length; i += 3) {
  //           pointArray.push(new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]));
  //         }
  //         // Sample control points evenly from the original PCD points
  //         P.controlPoints(pointArray,curveRef,scene,spheresRef);


  //         P.updateSpline(splineObjectRef,curveRef,meshRef,selectedSphereRef,scene,splineMaterial);


  //         dragControls.addEventListener('dragstart', () => {
  //           controls.enabled = false;
  //         });
    
  //   const onMouseMove = (event) => {
  //     if (isDraggingRef.current && selectedSphereRef.current && meshRef) {
  //       const rect = renderer.domElement.getBoundingClientRect();
  //       mouseRef.current.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
  //       mouseRef.current.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
  //       raycaster.setFromCamera(mouseRef.current, camera);
  //       const intersects = raycaster.intersectObject(meshRef);
    
  //       if (intersects.length > 0) {
  //         const closestPoint = intersects[0].point;
  //         const sphere = selectedSphereRef.current;
  //         sphere.position.copy(closestPoint)
    
  //         // Update the corresponding control point in the curve
  //         if (curveRef.current) {
  //           curveRef.current.points[sphere.userData.index].copy(closestPoint);
  //           P.updateSpline(splineObjectRef,curveRef,meshRef,selectedSphereRef,scene,splineMaterial);
  //         }
  //       }
  //     }
  //   };
    
  //   dragControls.addEventListener('dragstart', (event) => {
  //     controls.enabled = false;
  //     isDraggingRef.current = true;
  //     selectedSphereRef.current = event.object;
  //   });
    
  //   dragControls.addEventListener('dragend', () => {
  //     controls.enabled = true;
  //     isDraggingRef.current = false;
  //     selectedSphereRef.current = null;
  //   });
    
  //   // Add and remove the mousemove event listener
  //   renderer.domElement.addEventListener('mousemove', onMouseMove);
    
  //         dragControls.addEventListener('dragend', () => {
  //           controls.enabled = true;
  //         });
    
  //         dragControls.addEventListener('hoveron', (event) => {
  //           event.object.material.emissive?.setHex(0x333333);
  //         });
    
  //         dragControls.addEventListener('hoveroff', (event) => {
  //           event.object.material.emissive?.setHex(0x000000)
  //         });
  //       })
  //   }
  //   }
  // }, [threeJSManager,scene,meshRef]);


  useEffect(() => {
    if(threeJSManager && scene){
      let newSplinecurve
      const updateTubeGeometry = () => {
        if (selectedPoints.current.length >= 2) {
          if(scene.getObjectByName('redSpline')){
            const newSpline = scene.getObjectByName('redSpline');
            scene.remove(newSpline);
          }
          newSplinecurve = new THREE.CatmullRomCurve3(selectedPoints.current);
          const tubeGeometry = new THREE.TubeGeometry(newSplinecurve, selectedPoints.current.length * 5, 0.05, 8, false);
          const splineMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
          const tubeMesh = new THREE.Mesh(tubeGeometry, splineMaterial);
          tubeMesh.name = 'redSpline';
          scene.add(tubeMesh);
        }
      };
      const update = (maincurve, newcurve) => {
        maincurve = maincurve.getPoints(300);
        if (!newcurve) return;
      
        const start = newcurve.getPoint(0);
        const end = newcurve.getPoint(1);
        let startind = 0, endind = 0;
        let minstartDist = Infinity, minendDist = Infinity;
        
        maincurve.forEach((point, index) => {
          const startDist = point.distanceTo(start);
          const endDist = point.distanceTo(end);
          if (startDist < minstartDist) {
            minstartDist = startDist;
            startind = index;
          }
          if (endDist < minendDist) {
            minendDist = endDist;
            endind = index;
          }
        });
      
        // Ensure startind is always less than endind by swapping if necessary
        // if (startind > endind) {
        //   [startind, endind] = [endind, startind];
        // }
      
        // Create new curve by replacing points between start and end indices
        const newmaincurve = [
          ...maincurve.slice(0, startind + 1),
          ...newcurve.getPoints(100),
          ...maincurve.slice(endind)
        ];
      
        return newmaincurve;
      };
      
    const splineMaterial = new THREE.MeshStandardMaterial({
      color: 0x000000,
      metalness: 0.3,
      roughness: 0.4,
      emissive: 0x000000
    });
    const onMouseDown = (event) => {
      event.preventDefault();
      isDragging.current = true;
      selectedPoints.current = []; // Reset points for a new spline
    };

    // Mouse move event to add points while dragging
    const onMouseMove = (event) => {
      if (!isDragging.current) return;

      if(event.ctrlKey){
        controls.enabled = false;
      // Update mouse vector with normalized device coordinates
      const rect = renderer.domElement.getBoundingClientRect();
      mouseRef.current.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouseRef.current.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      // Cast ray and check for intersections with mesh
      raycaster.setFromCamera(mouseRef.current, camera);
      const prepMesh = scene.getObjectByName('prep');
      const intersects = raycaster.intersectObject(prepMesh);

      if (intersects.length > 0) {
        const point = intersects[0].point.clone();
        selectedPoints.current.push(point);
        updateTubeGeometry();
      }
    }
    };
    const onMouseUp = () => {
      try {
        const newmaincurve = update(curveRef.current, newSplinecurve);
        if (!newmaincurve) return;
        
        curveRef.current = new THREE.CatmullRomCurve3(newmaincurve, true);
        const orignalSpline = scene.getObjectByName('blackSpline');
        if (orignalSpline) scene.remove(orignalSpline);
        
        const newSpline = scene.getObjectByName('redSpline');
        if (newSpline) scene.remove(newSpline);
        
        const tube = new THREE.TubeGeometry(curveRef.current, 100, 0.05, 8, false);
        const newCombinedSpline = new THREE.Mesh(tube, splineMaterial);
        newCombinedSpline.name = 'blackSpline';
        scene.add(newCombinedSpline);
      } finally {
        isDragging.current = false;
        controls.enabled = true;  // Ensure controls are always re-enabled
      }
    };

    const onMouseLeave = () => {
      isDragging.current = false;
      controls.enabled = true;  // Reset controls if mouse leaves canvas
    };
    if (threeJSManager && margin) {
      const pcdLoader = new PCDLoader();
      const pcdBlob = new Blob([margin], { type: 'application/octet-stream' });
      const pcdUrl = URL.createObjectURL(pcdBlob);
    
      pcdLoader.load(pcdUrl, (points) => {
        const positions = points.geometry.attributes.position.array;
        const pointArray = [];
    
        for (let i = 0; i < positions.length; i += 3) {
          pointArray.push(new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]));
        }
    
        // Sample control points evenly from the original PCD points
        const numControlPoints = 300;
        const controlPoints = [];
        const stepSize = Math.floor(pointArray.length / numControlPoints);
        for (let i = 0; i < numControlPoints; i++) {
          controlPoints.push(pointArray[i * stepSize]);
        }
    
        curveRef.current = new THREE.CatmullRomCurve3(controlPoints, true);
        const tube = new THREE.TubeGeometry(curveRef.current, 100, 0.05, 8, false);
        const orignalMesh = new THREE.Mesh(tube, splineMaterial);
        orignalMesh.name = 'blackSpline';
        scene.add(orignalMesh);

        // const sphere = new THREE.SphereGeometry(0.5, 32, 32);
        // const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
        // const startingPoint = new THREE.Mesh(sphere, sphereMaterial);
        // startingPoint.position.copy(controlPoints[0]);
        // scene.add(startingPoint);
      });
    }

    renderer.domElement.addEventListener('mousedown', onMouseDown);
    renderer.domElement.addEventListener('mousemove', onMouseMove);
    renderer.domElement.addEventListener('mouseup', onMouseUp);
    renderer.domElement.addEventListener('mouseleave', onMouseLeave);

    return () => {
      renderer.domElement.removeEventListener('mousedown', onMouseDown);
      renderer.domElement.removeEventListener('mousemove', onMouseMove);
      renderer.domElement.removeEventListener('mouseup', onMouseUp);
      renderer.domElement.removeEventListener('mouseleave', onMouseLeave);
    }}
  }, [threeJSManager,scene,meshRef]);


  const handleToggleVisibility = (index) => {
    if (threeJSManager) {
      const updatedSliderData = [...sliderData];
    updatedSliderData[index].visible = !updatedSliderData[index].visible;
    setSliderData(updatedSliderData);
      threeJSManager.handleToggleVisibilityClick(index);
    }
  };
  
  const setDefault = () => {
    setIsRaycasterActive(false)
    setpoint(points);
    setPrepPoints([points]);
  }
  
  const capturePoint = () => {
    if (!selectedCoordinates) {
        // Show Bootstrap alert
        setShowAlert(true);
    } else {
        const updatedPrepPoints = [...prepPoints]; // Create a copy of prepPoints array
        const lastPointIndex = updatedPrepPoints.length - 1;

        if (lastPointIndex >= 0) {
            updatedPrepPoints[lastPointIndex] = [
                selectedCoordinates.x,
                selectedCoordinates.y,
                selectedCoordinates.z
            ];
            setPrepPoints(updatedPrepPoints); // Update the state with the updated array
           
        }
    }
};

  const handleAlertClose = () => {
    setShowAlert(false);
  };
 const showPrep = ()=>{
  setPrepview(!prepview);
 }


 const genCrown = async () => {
  if(prepPoints[prepPoints.length - 1][0] === 0 && prepPoints[prepPoints.length - 1][1] === 0 && prepPoints[prepPoints.length - 1][2] === 0){
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 6000);
    return;
  }
  try {
    setLoading(true);
    const formData = new FormData();
    formData.append('file1', file1);
    formData.append('file2', file2);
    formData.append('points', JSON.stringify(prepPoints));
    formData.append("category", JSON.stringify(selectedOption))

    const response = await fetch('https://api.dentalai.ai/gen_crown_multi/', {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const contentType = response.headers.get('content-type');

      if (contentType === 'application/json') {
        // Parse the JSON response
        const responseData = await response.json();

        // Extract data from the JSON response
        const {
          zip_file,
          margin_center: marginCenter,
          axis,
          thickness: thicknessData,
          inner_surface
        } = responseData;

        const thickness = thicknessData === true;
        const crownFirst = true;

        // Convert base64 zip data to a Blob
        const zipBlob = new Blob([Uint8Array.from(atob(zip_file), c => c.charCodeAt(0))], { type: 'application/zip' });

        // Process the ZIP blob to create separate File objects for each mesh
        const zip = await JSZip.loadAsync(zipBlob);

        const meshFiles = [];
        const promises = [];

        zip.forEach((relativePath, file) => {
          if (file.dir) return; // Ignore directories
          const promise = file.async('uint8array').then((data) => {
            const meshBlob = new Blob([data], { type: 'application/octet-stream' });
            const mesh = new File([meshBlob], file.name, { type: 'application/octet-stream' });
            meshFiles.push(mesh);
          });
          promises.push(promise);
        });

        // Wait for all promises to resolve
        await Promise.all(promises);

        navigate('/crown', {
          state: {
            file1,
            file2,
            crown: meshFiles,
            crownFirst,
            marginCenter,
            axis,
            thickness,
            position: initialCameraPosition,
            selectedOption,
            prepView: sliderData[0].visible,
            antaView: sliderData[1].visible,
            inner_surface
          }
        });

        try {
          const formData = new FormData();
          formData.append('prep', file1);
          formData.append('anta', file2);
          formData.append("crown", meshFiles[0])
          formData.append("category", JSON.stringify(selectedOption))
          
          const token = sessionStorage.getItem('token');
    
          const response1 = await fetch('https://api.dentalai.ai/saves3/', {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Token ${token}`
            }
          });
    
          if (response1.ok) {
            console.log('Mesh Saved successfully');
          }
        }
        catch (error) {
          console.error('Error adding mesh:', error.message);
        }
      } else {
        setError(true);
        setLoading(false);
      }
    }
    else {
      setError(true);
      setLoading(false);
    }
  } catch (error) {
    setError(true);
    setLoading(false);
    console.error('Error generating crown:', error);
  }
};
const getNewMargin = () => {
  const numPoints = 1500;
  const points = [];
  
  // Sample points along the curve including the closing segment
  for (let i = 0; i < numPoints; i++) {  // Changed from <= to < to avoid duplicate point
    const t = i / numPoints;
    const point = new THREE.Vector3();
    curveRef.current.getPoint(t, point);
    points.push(point);
  }

  // Create PCD header
  let header = `# .PCD v0.7 - Point Cloud Data\n`;
  header += `VERSION 0.7\n`;
  header += `FIELDS x y z\n`;
  header += `SIZE 4 4 4\n`;
  header += `TYPE F F F\n`;
  header += `COUNT 1 1 1\n`;
  header += `WIDTH ${points.length}\n`;
  header += `HEIGHT 1\n`;
  header += `VIEWPOINT 0 0 0 1 0 0 0\n`;
  header += `POINTS ${points.length}\n`;
  header += `DATA ascii\n`;

  // Create points data
  let pointsData = '';
  points.forEach(point => {
    const x = point.x.toFixed(6);
    const y = point.y.toFixed(6);
    const z = point.z.toFixed(6);
    pointsData += `${x} ${y} ${z}\n`;
  });

  // Combine header and points data
  const pcdData = header + pointsData;
  return pcdData;
};
  const anatomicalCrown = async () => {
    const pcdData = getNewMargin();
    const MarginPCD = new Blob([pcdData], { type: 'text/plain' });
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file1', file1);
      formData.append('file2', file2);
      formData.append('margin', MarginPCD);
      formData.append("category", JSON.stringify(selectedOption))
  
      const response = await fetch('https://api.dentalai.ai/gen_crown_anatomical/', {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const contentType = response.headers.get('content-type');
  
        if (contentType === 'application/json') {
          // Parse the JSON response
          const responseData = await response.json();
  
          // Extract data from the JSON response
          const {
            zip_file,
            margin_center: marginCenter,
            axis,
            thickness: thicknessData,
            inner_surface
          } = responseData;
  
          const thickness = thicknessData === true;
          const crownFirst = true;
  
          // Convert base64 zip data to a Blob
          const zipBlob = new Blob([Uint8Array.from(atob(zip_file), c => c.charCodeAt(0))], { type: 'application/zip' });
  
          // Process the ZIP blob to create separate File objects for each mesh
          const zip = await JSZip.loadAsync(zipBlob);
  
          const meshFiles = [];
          const promises = [];
  
          zip.forEach((relativePath, file) => {
            if (file.dir) return; // Ignore directories
            const promise = file.async('uint8array').then((data) => {
              const meshBlob = new Blob([data], { type: 'application/octet-stream' });
              const mesh = new File([meshBlob], file.name, { type: 'application/octet-stream' });
              meshFiles.push(mesh);
            });
            promises.push(promise);
          });
  
          // Wait for all promises to resolve
          await Promise.all(promises);
  
          navigate('/crown', {
            state: {
              file1,
              file2,
              crown: meshFiles,
              crownFirst,
              marginCenter,
              axis,
              thickness,
              position: initialCameraPosition,
              selectedOption,
              prepView: sliderData[0].visible,
              antaView: sliderData[1].visible,
              inner_surface
            }
          });
  
          try {
            const formData = new FormData();
            formData.append('prep', file1);
            formData.append('anta', file2);
            formData.append("crown", meshFiles[0])
            formData.append("category", JSON.stringify(selectedOption))
            
            const token = sessionStorage.getItem('token');
      
            const response1 = await fetch('https://api.dentalai.ai/saves3/', {
              method: 'POST',
              body: formData,
              headers: {
                'Authorization': `Token ${token}`
              }
            });
      
            if (response1.ok) {
              console.log('Mesh Saved successfully');
            }
          }
          catch (error) {
            console.error('Error adding mesh:', error.message);
          }
        } else {
          setError(true);
          setLoading(false);
        }
      }
      else {
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error('Error generating crown:', error);
    }
    
  }



  const addPrepPoint = () => {
    if(prepPoints.length>0 &&prepPoints[prepPoints.length - 1][0] === 0 && prepPoints[prepPoints.length - 1][1] === 0 && prepPoints[prepPoints.length - 1][2] === 0){
      alert('Please select a point before adding a new one');
      return;
    }
    setIsRaycasterActive(true);
    // Create a new copy of prepPoints with the new point added
    const newPrepPoints = [...prepPoints, [0, 0, 0]];
    setPrepPoints(newPrepPoints);
    
  };
  const setdefaultview = () => {
    setInitialCameraPosition(position ? position : new THREE.Vector3(2.6, -17,70));
  }
  const prepselection = (
    <div className="p-3 text-center position-absolute rounded-4" style={{minWidth:"15vw", zIndex: "4", background: "rgba(255, 255, 255, 1)", top: "20%", left: "-160%", transform: "translate(-50%, -50%)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)",fontWeight:"bold" }}>
  
      <div className='mb-3 d-flex justify-content-between align-items-center' >
        <div style={{fontWeight:"bold"}}>Prep Properties</div>
        <div onClick={showPrep}><Delete/></div>
      </div>
  
      <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{background: "rgba(144, 200, 224, 0.3)" }}>
        {prepPoints.map((item,index)=>(
          <div className='d-flex justify-content-between' key={index}>
            <div className='mx-3'>Prep {index+1}</div>
            {(prepPoints[index][0]===0 && prepPoints[index][1]===0 && prepPoints[index][2]===0  ) ? <div className='mx-3' onClick={capturePoint}><MySVG10/></div> : <div className='mx-3' onClick={() => {
             
    const updatedPrepPoints = prepPoints.filter((_, i) => i !== index);
    setPrepPoints(updatedPrepPoints);
    
}}><MySVG5/></div>}
            

          </div>
        ))}
      </ul>
      <div className='mt-3' style={{cursor:"pointer"}} onClick={addPrepPoint}><MySVG6/> Add Prep</div>
    </div>
  );
  const statusbar = (
    <>
    <>
    <div className="d-flex py-2 rounded" style={{ zIndex: "2", background: "rgba(144, 200, 224, 0.3)", width: "18vw", transform: "translate(50%, -50%)", boxShadow: "0 2px 4px rgba(144, 200, 224, 0.4)",justifyContent:"space-evenly" }}>
      <div className=" d-flex flex-column"><div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px', color: '#fff', transition: 'background-color 0.3s ease', backgroundColor: 'rgba(144, 238, 144, 0.5)', borderRadius: '50%' }}><PrepIcon /></div>Prep</div>
      <div className=" d-flex flex-column"><div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px', color: '#fff', transition: 'background-color 0.3s ease' }}><CrownIcon /></div>Crown</div>
      <div className=" d-flex flex-column"><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px', color: '#fff', transition: 'background-color 0.3s ease' }}><Export /></div>Export</div>
    </div>
  </>
    </>
  )
  
  const showPanel = () => {
    setAdjustmentPanel(!Adjustmentpanel);
  }


  const adjustment = (
    <div className="p-3 text-center position-absolute rounded-4" style={{ zIndex: "2", background: "rgba(144, 200, 224, 0.3)", top: "45%", right: "10%", transform: "translate(50%, -50%)" ,boxShadow: "0 2px 4px rgba(144, 200, 224, 0.4)", }}>
      <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={
        {
          cursor: 'pointer',
          transition:"transform 1s ease"

        }
      } >
  <MySVG className='mb-1' />
  <MySVG className='mb-1' />
</div>
    {/* Move prepview && prepselection inside the adjustment panel */}
    {prepview && prepselection}
    {Adjustmentpanel &&(<><div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{background:"rgba(255,255,255,1"}}>
      {/* Render prepview */}
      

      <div>Prep Panel</div>
      <OverlayTrigger
 
  placement="left"
  
  overlay={<Tooltip style={{
    backgroundColor: '#ffffff', // Change background color
    color: '#000000', // Change text color
  }} id="tooltip-prep">Modify Preps on the 3-D model, you can add additional preps or delete existing preps </Tooltip>}
>
      <button id="prep" className='rounded' onClick={showPrep} title='Use this button to Change prep selection' style={{ 
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        backgroundColor:  primarycolor,
        color: '#fff',
        border: "1px solid #1F555A",
        transition: 'background-color 0.3s ease',
      }} 
      >
      <MySVG4/>
      </button>
      </OverlayTrigger>
      <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{background:"rgba(255,255,255,1"}}>
  <div>Reset</div>
  <OverlayTrigger
 
  placement="left"
  overlay={<Tooltip id="tooltip-prep">Reset Preps and discard all manual changes(can’t be undone)
  </Tooltip>}
>
  <button className='rounded' 
  onClick={setDefault}
  style={{
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    backgroundColor:  primarycolor,
    color: '#fff',
    border: "1px solid #1F555A",
    transition: 'background-color 0.3s ease',
  }} ><Reset  color='#808080' /></button>
  </OverlayTrigger>
  
</div>
 
</div>

<hr style={{ width: "100%", border: "1px solid #000" }} />
        
        <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3 ' style={{background:"rgba(255,255,255,1"}}>
  {/* Model 0 */}
  <div className="mr-3 container ">
    <label className="container" style={{fontFamily: 'Manrope, sans-serif'}}>{sliderData[0].name}</label>
    <OverlayTrigger

  placement="left"
  overlay={<Tooltip id="tooltip-prep">Toggle Prep/Anta visibility</Tooltip>}
>
    <div
      className="container rounded-3"
      id={'toggleVisibilityButton-0'}
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        backgroundColor: sliderData[0].visible ? primarycolor : '#ffffff',
        color: '#fff',
        border: "1px solid #1F555A",
        
        transition: 'background-color 0.3s ease',
      }}
      onClick={() => handleToggleVisibility(0)}
    >
      {sliderData[0].visible ? <MySVG1  /> : <MySVG8/>}
    </div>
    </OverlayTrigger>
  </div>

  {/* Model 1 */}
  <div className="mr-3 container">
    <label className="container">{sliderData[1].name}</label>
    <OverlayTrigger
 
  placement="left"
  overlay={<Tooltip id="tooltip-prep">Toggle Prep/Anta visibility</Tooltip>}
>
    <div
      className="container rounded-3"
      id={'toggleVisibilityButton-1'}
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        backgroundColor: sliderData[1].visible ? primarycolor : '#ffffff',
        color: '#fff',
        border: "1px solid #1F555A",
        transition: 'background-color 0.3s ease',
      }}
      onClick={() => handleToggleVisibility(1)}
    >
      {sliderData[1].visible ?<MySVG9/>: <MySVG7/>}
    </div>
    </OverlayTrigger>
    
  </div>

</div>

<hr style={{ width: "100%", border: "1px solid #000" }} />
         
<div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{background:"rgba(255,255,255,1"}}>
  <div>Grid</div>
  <OverlayTrigger

  placement="left"
  overlay={<Tooltip id="tooltip-prep">Show/Hide grid View
  </Tooltip>}
>
  <button id='grid' className='rounded' 
  style={{
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    backgroundColor:  primarycolor,
    color: '#fff',
    border: "1px solid #1F555A",
    transition: 'background-color 0.3s ease',
  }} ><MySVG3  color='#808080' /></button></OverlayTrigger>
  <div>Wireframe</div>
  <OverlayTrigger
 
  placement="left"
  overlay={<Tooltip id="tooltip-prep">Show/Hide wireframe view
  </Tooltip>}
>
  <button id="wireframe" className='rounded' 
  style={{
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    backgroundColor:  primarycolor,
    color: '#fff',
    border: "1px solid #1F555A",
    transition: 'background-color 0.3s ease',
  }} >
    <MySVG2 />
  </button></OverlayTrigger>
  
</div>

</>
)}


        
        
  

      </div>
  )

  

  return (
    <div className="d-flex " style={{ overflow: 'hidden', backgroundColor: "#ffffff",fontFamily: 'Manrope, sans-serif',fontWeight:"bold" }}>
      {loading && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '9999',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '20px',
          borderRadius: '5px',
          textAlign: 'center',
          width:"50vw"
        }}>
           {/* <div className="mb-3">
              <h2 className="card-title mb-4 text-center fw-semibold">Do you know?</h2>
              <p>
                <strong> {randomFact.fact}</strong> 
              </p>
            </div> */}
          <ClipLoader size={50} color="#007bff" loading={loading}/>
        </div>
      )}
      <div className="flex-1 " style={{ position: 'relative' }}>
        <div id="canvas-container"  ref={containerRef}>
          {showAlert && (
            <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 9999,width:"72vw" }}>
             Your prep selection is incomplete, either select a point or remove the last prep point before proceeding.
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleAlertClose}></button>
            </div>
          )}
          {error && (
            <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 9999,width:"72vw" }}>
              Some Error Occured!
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleAlertClose}></button>
            </div>
          )}
          <div id='defaultview' style={{zIndex:2000 ,position:"absolute",left:"2%",top:"2%",cursor:"pointer"}} onClick={setdefaultview}>
          <OverlayTrigger
  key="tooltip-view"
  placement="bottom"
  overlay={<Tooltip id="tooltip-prep">Reset to default view
  </Tooltip>}
>
          <View/></OverlayTrigger>
          <div>Default View</div>
          
          </div>

          <div style={{zIndex:2000 ,position:"absolute",left:"30%",top:"10%"}}
>
  {statusbar}
</div>

          
        </div>
      </div>

      {adjustment}
      <div className='position-absolute ' style={{right: '10%',
      bottom: '3%'}}>
          
      <button
        className='btn btn-primary fs-5'
        style={{
          backgroundColor:primarycolor,
          textDecorationColor:"#ffffff",
          margin: '10px',
          
          fontWeight:"bolder",
          fontFamily: 'Manrope, sans-serif'
        }}
        onClick={anatomicalCrown}
      >
        Generate Crown
      </button>
      </div>
    </div>
  );
};

export default Prepmodule;

import Footer from './components/Footer';
import Mainpage from './components/Mainpage';
import Navbar from './components/Navbar';
import Signin from './components/Signin';
import  { Route,Routes,BrowserRouter } from 'react-router-dom';
import Signup from './components/Signup';
import Fileselect from './components/Fileselect';
import New_user from './components/New_user';
import Crown from './components/Crown';
import Listview from './components/Listview';
import Prepmodule from './components/prepModule';
import Export from './components/Export';
import Feedback from './components/Feedback';
import Changepassword from './components/Changepassword';
import PwdChange from './components/PwdChange';
import MeshDistortion from './components/Mesh';

function App() {
  return (
    <BrowserRouter>
    <Navbar />
    <Routes>
      <Route path="/" element={<><Mainpage/><Footer/></>}/>
      <Route path="/login" element={<><Signin/></>}/>
      <Route path='/prep' element={<Prepmodule/>}/>
      <Route path="/signup" element={<><Signup/></>}/>
      {/* <Route path="/proximal" element={<Proximal/>}/>
      <Route path="/shortmargin" element={<Shortmargin/>}/> */}
      <Route path="/reset-password/:uid/:token" element={<Changepassword />} />
      <Route path="/new_user/:uid/:token" element={<New_user />} />
      <Route path="forgot-password" element={<PwdChange/>} />
      <Route path="/example" element={<MeshDistortion/>} />
      <Route path="/feedback" element={<Feedback/>}/>
      <Route path="/crown" element={<Crown/>}/>
      <Route path = "/fileview" element={<Listview/>}/>
      {/* <Route path="/upload" element={<Upload/>}/> */}
      <Route path="/select" element={<Fileselect/>}/>
      {/* <Route path="/onboarding" element={<Onboarding/>}/> */}
      <Route path="/export" element={<><Export/></>}/>
      </Routes>
      
    </BrowserRouter>
    
      
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';

function Join() {
  return (
    <div style={{height:"50vh", backgroundColor: '#87CEEB', padding: '20px', textAlign: 'center', color: 'white' }}>
      <h1 className='fw-semibold container fs-1' style={{marginTop:"5%"}}>Join us in Revolutionizing the Dentistry</h1>
      <Link to="/select"><button className="btn btn-info fs-2" style={{ marginTop: '20px' }}>
        Get Started
      </button></Link>
    </div>
  );
}

export default Join;

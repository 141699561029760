import React from 'react';

function Footer() {
  return (
    <footer className=" text-dark pt-4 mt-auto" style={{ backgroundColor: '#ffffff', borderTop: '0.5px solid #808080', boxShadow: '0 -2px 2pxrgba(0, 0, 0, 0.1)' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a className="text-dark" href="/">Home</a></li>
              <li><a className="text-dark" href="/about">About Us</a></li>
              <li><a className="text-dark" href="/services">Services</a></li>
              <li><a className="text-dark" href="/contact">Contact Us</a></li>
            </ul>
          </div>
          <div className="col-md-6">
            <h5>Contact Us</h5>
            <address className="text-dark">
              123 Main Street<br />
              Cityville, State 12345<br />
              <a className="text-dark" href="tel:+123456789">Phone: +1 (234) 567-89</a><br />
              <a className="text-dark" href="mailto:info@example.com">Email: info@example.com</a>
            </address>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <p className="text-dark">&copy; 2023 DentalAI. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { Box, Card, CardContent, Typography } from "@mui/material";

const dataset = [
  {
    seoul: 18,
    month: "Jan",
  },
  {
    seoul: 2,
    month: "Feb",
  },
  {
    seoul: 41,
    month: "Mar",
  },
  {
    seoul: 13,
    month: "Apr",
  },
  {
    seoul: 20,
    month: "May",
  },
  {
    seoul: 36,
    month: "June",
  },
  {
    seoul: 19,
    month: "July",
  },
  {
    seoul: 55,
    month: "Aug",
  },
  {
    seoul: 33,
    month: "Sept",
  },
  {
    seoul: 55,
    month: "Oct",
  },
  {
    seoul: 8,
    month: "Nov",
  },
  {
    seoul: 25,
    month: "Dec",
  },
];

const valueFormatter = (value) => `${value}`;

const chartSetting = {
  yAxis: [
    {
      label: "Crowns",
    },
  ],
  xAxis: [
    {
      scaleType: "band", // Use band scale for the x-axis
      dataKey: "month",
      categoryGapRatio: 0.7,
      barGapRatio: 2.5,
    },
  ],
  series: [{ dataKey: "seoul", valueFormatter, color: "#213F99" }],
  height: 300,
  sx: {
    [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
      transform: "translateX(-10px)",
    },
    [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
      transform: "translateY(10px)",
    },
  },
};



export default function TickPlacementBars() {
  return (
    <Box sx={{ flex: 1, }}>
      {/* Top Cards */}
      <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", mb: 4 }}>
        {/* Total Crowns Card */}
        <Card
          sx={{
            flex: 1,
            backgroundColor: "ffffff",
            borderRadius: "10px",
            // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <CardContent>
            <Card>
              <CardContent>
                <div style={{ width: "100%" }}>
                  <BarChart
                    dataset={dataset}
                    xAxis={[
                      {
                        scaleType: "band",
                        dataKey: "month",
                        categoryGapRatio: 0.7,
                        barGapRatio: 2.5,
                      },
                    ]}
                    {...chartSetting}
                  />
                </div>
              </CardContent>
            </Card>
          </CardContent>
          <CardContent>
            
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
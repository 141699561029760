import React from 'react';
import img3 from '../Images/img2.png';

function Benefits() {
  return (
    <div className="pt-5 " style={{ height:"60vh",backgroundColor: "#f0f8ff", padding: "2%" }}>
      <h2 className="mb-4 text-center fs-2 fw-semibold">We care about your time and money</h2>
      <div className="row">
        {/* Image on the right (col-md-3) */}
        <div className="col-md-3">
          <img src={img3} alt="Benefits" className="img-fluid rounded" />
        </div>

        {/* Cards on the left (col-md-9) */}
        <div className="col-md-9">
          <div className="row">
            {/* Upper-left card */}
            <div className="col-md-6 mb-4">
              <div className="card h-100 shadow"> {/* Added shadow for depth */}
                <div className="card-body">
                  <h5 className="card-title">Save time</h5>
                  <p className="card-text">Upload data and click 3 times. Then it's ready to go.</p>
                </div>
              </div>
            </div>

            {/* Upper-right card */}
            <div className="col-md-6 mb-4">
              <div className="card h-100 shadow"> {/* Added shadow for depth */}
                <div className="card-body">
                  <h5 className="card-title">Start free</h5>
                  <p className="card-text">No installations or dongles. Pay only for the designs you choose.</p>
                </div>
              </div>
            </div>

            {/* Lower-left card */}
            <div className="col-md-6">
              <div className="card h-100 shadow"> {/* Added shadow for depth */}
                <div className="card-body">
                  <h5 className="card-title">Go online</h5>
                  <p className="card-text">Access from anywhere, anytime, on any device.</p>
                </div>
              </div>
            </div>

            {/* Lower-right card */}
            <div className="col-md-6">
              <div className="card h-100 shadow"> {/* Added shadow for depth */}
                <div className="card-body">
                  <h5 className="card-title">Maximize profit</h5>
                  <p className="card-text">Boost revenue and improve efficiency while keeping the cost down.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits;

// ThreeJSManager.js
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {TrackballControls} from 'three/examples/jsm/controls/TrackballControls';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { CSG } from 'three-csg-ts';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import {toCreasedNormals} from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import * as U from './utils';
import { debounce } from 'lodash';
const { kdTree } = require('kd-tree-javascript');
const vertexShader = `
  varying vec3 vNormal;
  varying vec3 vViewPosition;
  varying vec3 vLightDirection;

  uniform vec3 lightPosition;

  void main() {
      vNormal = normalMatrix * normal;
      vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
      vViewPosition = -mvPosition.xyz;
      vLightDirection = normalize(lightPosition - mvPosition.xyz);
      gl_Position = projectionMatrix * mvPosition;
  }
`;



const fragmentShader = `
  uniform vec3 diffuse;
  uniform vec3 specular;
  uniform float shininess;
  uniform float opacity;
  uniform float translucency;
  uniform float clearcoat;
  uniform float clearcoatRoughness;
  uniform float roughness;

  uniform vec3 lightColor;
  uniform vec3 ambientLightColor;

  varying vec3 vNormal;
  varying vec3 vViewPosition;
  varying vec3 vLightDirection;

  // Function to calculate specular reflection with roughness
  float distributionGGX(vec3 N, vec3 H, float roughness) {
    float a = roughness * roughness;
    float a2 = a * a;
    float NdotH = max(dot(N, H), 0.0);
    float NdotH2 = NdotH * NdotH;

    float nom   = a2;
    float denom = (NdotH2 * (a2 - 1.0) + 1.0);
    denom = 3.14159265359 * denom * denom;

    return nom / denom;
  }

  void main() {
  vec3 normal = normalize(gl_FrontFacing ? vNormal : -vNormal);
  vec3 viewDirection = normalize(vViewPosition);
  vec3 lightDirection = normalize(vLightDirection);
  vec3 halfwayDir = normalize(lightDirection + viewDirection);

  // Diffuse component
  float lambertian = max(dot(normal, lightDirection), 0.0);

  // Specular component with roughness
  float specularFactor = distributionGGX(normal, halfwayDir, roughness) * 0.5;

  // Subsurface scattering approximation
  float sss = pow(max(dot(-viewDirection, lightDirection), 0.0), 2.0) * translucency;

  // Fresnel effect for edge highlights
  float fresnel = pow(1.0 - max(dot(viewDirection, normal), 0.0), 5.0);

  // Clearcoat
  float clearcoatSpecular = distributionGGX(normal, halfwayDir, clearcoatRoughness);

  // Combine lighting components
  vec3 color = ambientLightColor * diffuse + 
               lambertian * lightColor * diffuse +
               specularFactor * lightColor * specular +
               sss * lightColor * diffuse +
               fresnel * specular;

  // Add clearcoat
  color += clearcoat * clearcoatSpecular * lightColor;

  // Set the fragment color and apply the opacity
  gl_FragColor = vec4(color, opacity);
}

`;

export default class ThreeJSManager {
  constructor(containerId, initialCameraPosition, color, sliderData, wire, crownarray, file1, file2, crown, prepView, antaView, scene, renderer, camera, controls) {
    this.containerId = containerId;
    this.initialCameraPosition = initialCameraPosition;
    this.color = color;
    this.sliderData = sliderData;
    this.wire = wire;
    this.crownarray = crownarray;
    this.file1 = file1;
    this.file2 = file2;
    this.crown = crown;
    this.prepView = prepView;
    this.antaView = antaView;
    this.scene = scene;
    this.renderer = renderer;
    this.camera = camera;
    this.controls = controls;
    this.raycasterSphereRef = null;
    this.controlsChanging = false;
    this.controlsChangeTimeout = null;
    this.gridScene = null;
  this.gridCamera = null
  }
  setupFixedGrid(color = 0x333333, opacity = 0.5) {
    // Create a separate scene for the grid
    this.gridScene = new THREE.Scene();
  
    // Create an orthographic camera for the grid
    const aspect = window.innerWidth / window.innerHeight;
    this.gridCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1000, 1000);
    this.gridCamera.position.z = 10;
  
    // Create the grid
    const size = 2;  // This will now represent the full width of the screen
    const divisions = 20;
    const gridHelper = new THREE.GridHelper(size, divisions, color, color);
    gridHelper.rotation.x = Math.PI / 2;
    gridHelper.material.opacity = opacity;
    gridHelper.material.transparent = true;
    gridHelper.material.depthWrite = false; // Important for proper rendering order
  
    // Scale the grid to fill the screen
    gridHelper.scale.set(1, aspect, 1);
  
    // Add the grid to the separate scene
    gridHelper.name = 'gridhelper'; // Assigning the name 'grid' to the gridHelper
    this.gridScene.add(gridHelper);
  
    // Store a reference to the gridHelper for resizing
    this.gridHelper = gridHelper;
  }

  initRenderer() {
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(window.innerWidth * 1, window.innerHeight * 0.9);
    this.renderer.setClearColor(0x000000, 0); // Set clear color to transparent
    this.renderer.autoClear = false; // Important for manual control of rendering order
    document.getElementById(this.containerId).appendChild(this.renderer.domElement);
    console.log("renderer", this.renderer);
  }

  initScene() {
    this.scene = new THREE.Scene();
    this.scene.add(new THREE.AmbientLight(0xffffff, 0.4));
  }

  initCamera() {
    const aspect = window.innerWidth / window.innerHeight;
    const frustumSize = 100;  // Adjust this value to control the visible area
  
    this.camera = new THREE.OrthographicCamera(
      frustumSize * aspect / -2,
      frustumSize * aspect / 2,
      frustumSize / 2,
      frustumSize / -2,
      0.1,
      1000
    );
  
    this.camera.up.set(0, 0, 1);
    this.camera.position.copy(this.initialCameraPosition);
    this.scene.add(this.camera);
  }

  initControls() {
    this.controls = new TrackballControls(this.camera, this.renderer.domElement);
    this.controls.enableRotate = true;
    this.controls.noPan = false;
    this.controls.enablePan = true;
    this.controls.enableZoom = true;
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.8;
    this.controls.rotateSpeed = 2.5;
    this.controls.zoomSpeed = 3;
    this.controls.panSpeed = 3.5;
    // this.controls.screenSpacePanning = true;
    this.controls.minPolarAngle = 0;
    this.controls.maxPolarAngle = Math.PI;
    this.controls.minAzimuthAngle = -Infinity;
    this.controls.maxAzimuthAngle = Infinity;
    this.controls.maxDistance = 500; // Maximum zoom distance
    this.controls.minDistance = 5;   // Minimum zoom distance
    this.controls.enableKeys = true;
    this.controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.ROTATE,
    };
    
  }
  



  toggleAxes() {
    this.showAxis = !this.showAxis;

    if (this.showAxis) {
      this.setupFixedGrid();
    } else {
      const axesHelper = this.gridScene.getObjectByName('gridhelper');
      if (axesHelper) {
        console.log('Removing grid');
        this.gridScene.remove(axesHelper);
      }
    }
  }

  toggleWireframe() {
    this.showWireframe = !this.showWireframe;

    this.scene.traverse(object => {
      if (object instanceof THREE.Mesh) {
        object.material.wireframe = this.showWireframe;
      }
    });
  }
  defaultView() {
    const startPosition = this.camera.position.clone();
    const startTarget = this.controls.target.clone();
    const targetPosition = new THREE.Vector3().copy(this.initialCameraPosition);
    const targetCenter = new THREE.Vector3(this.centerPoint);

    const duration = 500;
    const interval = 16;

    const totalSteps = Math.ceil(duration / interval);
    let currentStep = 0;

    const transitionStep = () => {
      currentStep++;

      const progress = currentStep / totalSteps;
      const newPosition = startPosition.clone().lerp(targetPosition, progress);
      const newTarget = startTarget.clone().lerp(targetCenter, progress);

      this.camera.position.copy(newPosition);
      this.controls.target.copy(newTarget);
      this.controls.update();

      if (currentStep < totalSteps) {
        requestAnimationFrame(transitionStep);
      }
    };

    transitionStep();
  }

  handleOpacityChange(index) {
    const fileNames = [...this.crownarray.map((_, crownIndex) => `crown_${crownIndex}`)];
    const fileName = fileNames[index];
    const existingMesh = this.scene.getObjectByName(fileName);
  
    if (existingMesh) {
      console.log(`Changing opacity for ${fileName}`);
      const opacityValue = parseFloat(document.getElementById(`opacitySlider-${index}`).value);
      console.log(`Opacity value: ${opacityValue}`);
  
      // Dynamically adjust renderOrder based on opacity
        existingMesh.renderOrder = 1;
      
  
      existingMesh.material.uniforms.opacity.value = opacityValue;
      existingMesh.material.transparent = opacityValue < 1;
      existingMesh.material.needsUpdate = true;
  
      this.renderer.render(this.scene, this.camera);
    }
  }

  createOpacitySlider(index) {
    const slider = document.getElementById(`opacitySlider-${index}`);

    const handleSliderChange = () => {
      this.handleOpacityChange(index);
    };

    slider.addEventListener('input', handleSliderChange);

    return () => {
      slider.removeEventListener('input', handleSliderChange);
    };
  }

  createAllOpacitySliders() {
    const crownsliders = this.crown.map((_, index) => this.createOpacitySlider(index));
    return crownsliders;
  }


  cleanup() {
    this.scene.traverse(object => {
      if (object instanceof THREE.Mesh) {
        object.geometry.dispose();
        object.material.dispose();
      }
    });
    this.renderer.domElement.remove();
    this.renderer.dispose();
    this.controls.dispose();
  }
  setrender() {
    return this.renderer;
  }
  animate() {
  requestAnimationFrame(this.animate.bind(this));
  this.controls.update();

  // Clear the renderer
  this.renderer.clear();

  // Render the grid scene first (behind everything else)
  if (this.gridScene && this.gridCamera) {
    this.renderer.render(this.gridScene, this.gridCamera);
  }

  // Render the main scene on top of the grid
  this.renderer.render(this.scene, this.camera);
}

  loadSTL(loader, file, materialOptions, fileName) {
    return new Promise((resolve, reject) => {
      loader.load(
        URL.createObjectURL(file),
        (geometry) => {
        geometry = toCreasedNormals(geometry, (90 / 180) * Math.PI)
        if(fileName.startsWith("crown")){
            // const geo = U.parseGeometry(geometry);
            // console.log("geo", geo)
        }
          const materialUniforms = {
            diffuse: { value: new THREE.Color(materialOptions.color || 0xf2f2e8) },
            specular: { value: new THREE.Color(0x111111) },
            shininess: { value: 100 },
            opacity: { value: 1.0 },
            translucency: { value: 0.5 },
            clearcoat: { value: 0 },
            clearcoatRoughness: { value: 0.1 },
            roughness: { value: 1},  // New: overall surface roughness
            lightPosition: { value: new THREE.Vector3(0, 0, 100) },
            lightColor: { value: new THREE.Color(0xffffff).multiplyScalar(0.9) }, // Reduce main light intensity
            ambientLightColor: { value: new THREE.Color(0x404040).multiplyScalar(1.8) },
            transparent: true,
          };
          
          const material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            uniforms: materialUniforms,
            transparent: true,
  side: THREE.DoubleSide
          });

          const mesh = new THREE.Mesh(geometry, material);
          mesh.scale.set(1, 1, 1);
          mesh.castShadow = true;
          mesh.receiveShadow = true;
          mesh.name = fileName;
          this.scene.add(mesh);

          if (file === this.file1) {
            mesh.visible = this.sliderData[0].visible;
            mesh.material.depthWrite = true;
            mesh.renderOrder = 0;
            
          } else if (file === this.file2) {
            mesh.visible = this.sliderData[1].visible;
            mesh.material.depthWrite = true;
            mesh.renderOrder = 0;
          } else if (file === this.crownarray[0]) {
            mesh.visible = this.sliderData[2].visible;
            const boundingBoxFile1 = new THREE.Box3().setFromObject(mesh);
            const centerPoint = new THREE.Vector3();
            boundingBoxFile1.getCenter(centerPoint);
            this.controls.target.copy(centerPoint);
          }

          if (fileName.startsWith('crown_')) {
            // Existing changes
            mesh.material.depthWrite = true;
            mesh.renderOrder = -1;
          }
          resolve(mesh);
        },
        undefined,
        (error) => {
          reject(error);
        }
      );
    });
  }

  async loadAllSTLs() {
    const loader = new STLLoader();
    const loadPromises = [];

    if (this.file1 && this.prepView) {
      loadPromises.push(this.loadSTL(loader, this.file1, {
      }, "prep"));
    }

    if (this.file2 && this.antaView) {
      loadPromises.push(this.loadSTL(loader, this.file2, {
        color: 0xf2f2e8,
        roughness: 1,
        reflectivity: 1.0,
        transmission: 0.0,
        side: THREE.DoubleSide,
        wireframe: this.wire,
        transparent: true,
        wireframeLinewidth: 0.1,
        wireframeLinecap: 'round',
        wireframeLinejoin: 'round',
      }, 'anta'));
    }

  }

  loadcrowns(newCrownArray, callback) {
    // Remove existing crown meshes
    if (Array.isArray(this.crownarray)) {
      this.crownarray.forEach((_, index) => {
        const existingMesh = this.scene.getObjectByName(`crown_${index}`);
        if (existingMesh) {
          this.scene.remove(existingMesh);
          existingMesh.geometry.dispose();
          existingMesh.material.dispose();
        }
      });
    }
  
    // Update crown array
    this.crownarray = newCrownArray;
  
    // Load new crowns
    const loader = new STLLoader();
    const loadPromises = [];
    if (Array.isArray(this.crownarray) && this.crownarray.length > 0) {
      this.crownarray.forEach((crownFile, index) => {
        loadPromises.push(this.loadSTL(loader, crownFile, {
          color: 0xFFDAB9,
        }, `crown_${index}`));
      });
    }
  
    // Wait for all promises to resolve
    Promise.all(loadPromises).then(() => {
      console.log('All crowns loaded successfully');
      // Execute the callback function if it's provided
      if (typeof callback === 'function') {
        callback();
      }
    }).catch(error => {
      console.error('Error loading crowns:', error);
      // You might want to call the callback even in case of an error,
      // depending on your error handling strategy
      if (typeof callback === 'function') {
        callback(error);
      }
    });
  }
  

  handleToggleVisibilityClick(index) {
    let fileNameToRemove;
    let newFile;
    let isCrown = false;
  
    if (index === 0) {
      fileNameToRemove = 'prep';
      newFile = this.file1;
    } else if (index === 1) {
      fileNameToRemove = 'anta';
      newFile = this.file2;
    } else if (index >= 2 && index < 2 + this.crownarray.length) {
      const crownIndex = index - 2;
      fileNameToRemove = `crown_${crownIndex}`;
      newFile = this.crownarray[crownIndex];
      isCrown = true;
    } else {
      return; // Invalid index, do nothing
    }
  
    const objectToToggle = this.scene.getObjectByName(fileNameToRemove);
  
    if (objectToToggle) {
      // Object exists, remove it
      console.log(`Removing ${fileNameToRemove}`);
      this.scene.remove(objectToToggle);
      objectToToggle.geometry.dispose();
      objectToToggle.material.dispose();
    } else {
      // Object doesn't exist, load it
      console.log(`Loading ${fileNameToRemove}`);
      const loader = new STLLoader();
      const crownColor = isCrown ? 0xFFDAB9 : 0xf2f2e8;
  
      this.loadSTL(loader, newFile, {
        color: crownColor,
      }, fileNameToRemove).then(mesh => {
        // Ensure mesh is named correctly and added to the scene
        mesh.name = fileNameToRemove;
        this.scene.add(mesh);
      }).catch(error => {
        console.error(`Error loading ${fileNameToRemove}:`, error);
      });
    }
  }
  
  
}

